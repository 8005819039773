const beerList = {
  "6": "6",
  "8": "8",
  "10": "10",
  "11": "11",
  "15": "10",
  "16": "10",
  "1809": "5",
  "1837": "7",
  "1997": "6.8",
  "#9": "4.5",
  "(512) ALT": "6",
  "(512) Bruin": "7.5",
  "(512) IPA": "7",
  "(512) Pale": "5.8",
  "(512) Pecan Porter": "6.8",
  "(512) Whiskey Barrel Aged Double Pecan Porter": "8.1",
  "(512) Wit": "5.1",
  ".38 Special Bitter": "3.7",
  "10 Squared (10Â²)": "10",
  "1000 Barley Wine": "9.1",
  "12 Dogs Christmas Ale": "7.8",
  "13th Anniversary Ale": "9.5",
  "1554 Enlightened Black Ale": "5.5",
  "1784 Anniversary Beer": "5.4",
  "1888 Bock": "5.1",
  "1916 Irish Stout": "3.5",
  "2004 Symposium Ale": "7.5",
  "2008 Culture Beer": "5",
  "21A IPA": "7.1",
  "2x4 India Pale Ale": "7",
  "2Â° Below": "6.5",
  "3 Monts": "8.5",
  "3767 Belgian-style IPA with Brett": "8",
  "3rd Anniversary Ale": "10",
  "4 Seasons IPA": "8",
  "420 Extra Pale Ale": "5.4",
  "422 Pale Wheat Ale": "5",
  "47 Bryg": "7",
  "471 Double IPA": "9.1",
  "471 Extra ESB": "7.8",
  "471 IPA": "9.1",
  "5 Barrel Pale Ale": "5.3",
  "5 C's IPA": "6.5",
  "555 IPA": "6.2",
  "563 Stout": "5",
  "60 Minute IPA": "6",
  "60 Shilling Scotch Ale": "5",
  "75 Minute IPA": "7.5",
  "77 Lager": "4.9",
  "7th Anniversary IPA": "7.6",
  "8th Street Ale": "4.5",
  "90 Minute IPA": "9",
  "90 Shilling": "5.3",
  "9th Anniversary IPA": "7.5",
  "A. LeCoq Imperial Extra Double Stout 1999": "9",
  "A. LeCoq Imperial Extra Double Stout 2000": "9",
  "AK-47 Malt Liquor": "6",
  "Abbaye de Floreffe Double": "6.3",
  "Abbaye de Saint-Martin Blonde": "7",
  "Abbaye de Saint-Martin Brune": "8",
  "Abbaye de Saint-Martin CuvÃ©e de Noel": "8.5",
  "Abbaye de Saint-Martin Triple": "9",
  "Abbaye de St Amand": "7",
  "Abbey 8": "8",
  "Abbey Belgian Style Ale": "7",
  "Abbey Dubbel": "8.5",
  "Abbey Extra": "4.5",
  "Abbey IPA": "7",
  "Abbey Roade Belgian Ale": "6.4",
  "Abbey Triple": "9",
  "Abbot Pennings Grand Cru": "10",
  "Abijah Rowe IPA": "6.8",
  "Abita Jockamo IPA": "6.5",
  "Abita Purple Haze": "4.7",
  "Abt 12": "10",
  "Abtskelder Tripel": "8.3",
  "Achel Blond 5Â°": "5",
  "Achel Blond 8Â°": "8",
  "Achel Bruin 5Â°": "5",
  "Achel Bruin 8Â°": "8",
  "Achel Trappist Extra": "9.5",
  "Acme California Brown Ale": "5.5",
  "Acme California IPA": "6.5",
  "Acme California Pale Ale": "5",
  "Adam": "10",
  "Adnam's Suffolk Special Bitter": "4.5",
  "Adnams Explorer": "5.5",
  "Adnams Fisherman": "4.5",
  "Adnams Tally Ho": "7.5",
  "Adriaan": "5",
  "Aecht Schlenkerla Rauchbier Urbock": "6",
  "Affligem Dubbel": "6.8",
  "Afterburner IPA": "6",
  "Alaskan Amber": "5.3",
  "Alaskan ESB": "5",
  "Alaskan IPA": "6.1",
  "Alaskan Pale": "5.1",
  "Alaskan Smoked Porter": "6.5",
  "Alaskan Stout": "5.6",
  "Alaskan Summer Ale": "5.3",
  "Alaskan White Ale": "5.3",
  "Alba Scots Pine Ale": "7.5",
  "Albion Amber Ale": "4.9",
  "Ale": "4.4",
  "Ale Mary": "6",
  "Ale To The Chief": "8.7",
  "Alembic Pale": "5.5",
  "Alena": "6.5",
  "Allagash Fluxus 09": "8.3",
  "Allagash White": "5",
  "Alpha": "6.1",
  "Alpha King Pale Ale": "6.5",
  "Alpha Klaus Xmas Porter": "7.5",
  "Alt": "4.5",
  "Alt Ball and Chain": "5.1",
  "Alt-Er-Ego Amber": "5",
  "Altbairisch Dunkel": "5.5",
  "Altbier": "5.0",
  "Amber": "5",
  "Amber Ale": "5.0",
  "Amber Waves": "5.3",
  "Amber Weizen": "5.5",
  "Ambree": "9",
  "Ambrosia Maibock": "5.3",
  "Amendment Pale Ale": "5.1",
  "American Amber Ale": "5.5",
  "American Brown Ale": "6.5",
  "American Pale Ale": "5.1",
  "American Wheat": "4.6",
  "American-Style Brown Ale": "5",
  "Amherst ESB": "5.3",
  "Amigo Lager": "5",
  "Amish Four Grain": "5.5",
  "Amstel Light": "3.5",
  "Anchor Steam": "4.9",
  "Anchor Summer Beer": "4.5",
  "Anchor Witbier with Brettanomyces": "6.2",
  "Andelot Angelique": "7",
  "Andelot Cuvee Diabolique": "8.5",
  "Andelot Euphorique": "6.5",
  "Andelot Mystique": "8.5",
  "Andygator": "8",
  "Angry Angel": "4.5",
  "Angry Planet Pale Ale": "6.0",
  "Anne Bonny Irish Stout": "3.9",
  "Anniversary Ale": "9",
  "Anniversary Ale 2007": "5.9",
  "Anniversary IPA Ahtanum": "7.1",
  "Anniversary IPA Glacier": "7.1",
  "Anniversary Maibock": "7.1",
  "Antler Brown Ale": "4.8",
  "Anvil Ale": "4.8",
  "Apache Trout Stout": "5",
  "Apex": "7.5",
  "Apocalypse Cow": "10",
  "Apricot Ale": "5.4",
  "Apricot Weizen Ale": "5.0",
  "Apricot Wheat": "5.0",
  "Aprihop": "7",
  "Arabier": "9",
  "Arc Weld Alt": "5.3",
  "Arctic Panzer Wolf": "9",
  "Arctic Rhino Coffee Porter": "5",
  "Arizona Peach": "4",
  "Arrogant Bastard Ale": "7.1",
  "Arrogant Brit": "5.3",
  "Arthur's English Old Ale": "7.1",
  "Arthur's Mild Ale": "3.2",
  "Arthur's Nugget Pale Ale": "5.1",
  "Artist Colony Ale": "5.5",
  "Asam-Bock": "6.9",
  "Ashland Amber": "5.5",
  "Athenian": "5",
  "Atlas Lager": "3.7",
  "Atomic Raygun Imperial Red": "8.3",
  "Atta Boy IPA": "7.3",
  "Auburn Ale": "5",
  "Aud Blonde": "4.5",
  "Augustijn Ale": "8",
  "Augustiner": "4.3",
  "Augustinian Ale": "5.1",
  "Autumn Ale": "6.6",
  "Autumn Maple": "10",
  "AutumnFest": "5.4",
  "Avalanche Amber": "5.4",
  "Avalanche IPA": "6.9",
  "Avant Garde": "7",
  "Avatar Jasmine IPA": "6.1",
  "Avec Les Bons Voeux": "9.5",
  "Aventinus Weizen-Eisbock": "12",
  "Aventinus Weizenstarkbier / Doppel Weizen Bock": "8.1",
  "Avenue Ale": "4.0",
  "Aviator Red": "5.2",
  "B.O.R.I.S. The Crusher Oatmeal-Imperial Stout": "9.3",
  "B3K Schwarzbier": "5.3",
  "BJ's Annual Grand Cru": "10",
  "Bachelor Bitter": "5.4",
  "Bachelor ESB": "5.3",
  "Back Burner Imperial Barley Wine Style Ale": "10",
  "Back Road Ale": "5",
  "Back Road American Pale Ale": "5",
  "Backyard India Pale Ale": "6.0",
  "Bad Billy Blond Bock": "6.1",
  "Bad Moon Porter": "5.0",
  "Bad Penny": "5.5",
  "Bagpiper's Scottish Ale": "9",
  "Bah Humbug": "6",
  "Bajuvator Doppelbock": "7.1",
  "Bak": "7.3",
  "Balashi": "5",
  "Baltas Alus": "5.1",
  "Baltic Porter": "7.5",
  "Baltic Thunder": "8.5",
  "Baltika #5": "5.3",
  "Baltika #8": "5",
  "Baltika #9": "8",
  "Baltika 6": "7",
  "Baltika 6 Porter": "7",
  "Bam BiÃ¨re": "4.5",
  "Bam Noire": "4.3",
  "Bamberger Gold": "5",
  "Bamberger Herren Pils": "4.5",
  "Bankers Gold": "4.5",
  "Bar Harbor Real Ale": "5.1",
  "Barelegs Brew": "4.5",
  "Barktoberfest": "6",
  "Barley Wine Ale": "10",
  "Barleywine": "10",
  "Barney Flats Oatmeal Stout": "5.6",
  "Barnstormer Brown Ale": "5",
  "Barnstormer Pale Ale": "4.8",
  "Barock-Dunkel": "4.6",
  "Baron Helles Bock": "6.4",
  "Baron Pilsner": "4.6",
  "Baron Schwarzbier": "4.9",
  "Barrel Aged Synapse ESB": "6",
  "Barrel-Aged B.O.R.I.S. Imperial Stout": "9.3",
  "Barrel-Aged Blind Date Ale": "7.5",
  "Barristers Bitter": "4",
  "Bartles and Lager": "4",
  "Base Camp Golden Ale": "4.9",
  "Bass Pale Ale": "5",
  "Batch 666": "7.6",
  "Batch 7000": "12",
  "Batch 8000": "9",
  "Bathtub Gin Gruit Ale": "8",
  "Bavarian Dark": "4",
  "Bavarian Lager": "5",
  "Bavarian-Weissbier Hefeweisse / Weisser Hirsch": "5.1",
  "Bayrisch Hell": "4.6",
  "Beach Bum Blonde Ale": "5.4",
  "Bear Ale": "5",
  "Bear Tooth Ale": "6.1",
  "Beast Bitter": "5.3",
  "BeckÂ´s": "4.5",
  "Bee Sting Honey Ale": "5.9",
  "Bee Sting Honey Rye Ale": "6.5",
  "Beer Esteem": "5.5",
  "Beer Geek Breakfast": "7.5",
  "Beewolf Braggot": "7",
  "Belgian Abbey Dubbel": "7",
  "Belgian Burgundy Ale": "9",
  "Belgian Double": "7",
  "Belgian Freeze Winter Ale": "8",
  "Belgian White": "4.0",
  "Bell's Cherry Stout": "7",
  "Belle Gunness Stout": "5",
  "Bellegems Bruin": "5.5",
  "Below Decks": "10",
  "Belzebuth": "13",
  "Benchmark Old Ale": "9.8",
  "Benchwarmer Porter": "6.4",
  "Bender Beer": "5.0",
  "Bengali Tiger": "6.5",
  "Berghoff Oktoberfest Beer": "5.1",
  "Berghoff Original Lager Beer": "5.5",
  "Best Bitter": "5.5",
  "Best Brown Ale": "5.8",
  "Best Bull": "5.9",
  "Beyond The Gates Double IPA": "7.5",
  "Bhagwan's Best IPA": "5",
  "Biere de Mars": "7",
  "Big A IPA": "9.6",
  "Big Bear Black Stout": "8.1",
  "Big Black Voodoo Daddy": "12",
  "Big Boat Oatmeal Stout": "6.5",
  "Big Buck Beer": "5.1",
  "Big Butt Doppelbock": "5.8",
  "Big Daddy IPA": "6.5",
  "Big Dick's Olde Ale": "8.1",
  "Big Eye IPA": "6",
  "Big Hop Harvest Ale": "7.4",
  "Big Hop IPA": "5.8",
  "Big Hoppy Monster": "8.7",
  "Big Mac": "5",
  "Big Nasty Porter": "6.4",
  "Big Porch Ale": "5.8",
  "Big Red Imperial Red Ale": "9.5",
  "Big Sound Scotch Ale": "8.5",
  "Big Swell IPA": "6.1",
  "Bigfoot": "9.6",
  "Bigfoot 1991": "9.6",
  "Bigfoot 1994": "9.6",
  "Bigfoot 1996": "9.6",
  "Bigfoot 1997": "9.6",
  "Bigfoot 1998": "9.6",
  "Bigfoot 1999": "9.6",
  "Bigfoot 2000": "9.6",
  "Bigfoot 2001": "9.6",
  "Bigfoot 2002": "9.6",
  "Bigfoot 2003": "9.6",
  "Bigfoot 2004": "9.6",
  "Bigfoot 2005": "9.6",
  "Bigfoot 2006": "9.6",
  "Bikini Blonde Lager": "3.7",
  "Billtown Blonde": "6",
  "Bird of Prey IPA": "8.2",
  "Birell": "0.5",
  "Birra Moretti La Rossa": "7.1",
  "Biscotti": "7",
  "Bishops Finger Kentish Strong Ale": "5.4",
  "Bison Brown": "5",
  "Bistro Blonde": "4.1",
  "Bitter": "4",
  "Bitter American": "3.5",
  "Bitter Woman IPA": "5.7",
  "Bitter and Twisted": "3.7",
  "Bittersweet Lenny R.I.P.A.": "10",
  "Black & Blue": "10",
  "Black Abbot / Schwarzer Abt": "3.9",
  "Black Albert": "13",
  "Black Bavarian Lager": "5.8",
  "Black Betty Schwartzbier": "5",
  "Black Butte Porter": "5.1",
  "Black Butte XXI": "11",
  "Black Cat": "3.4",
  "Black Cat Stout": "6.1",
  "Black Chocolate Stout": "10",
  "Black H2O Oatmeal Stout": "6.1",
  "Black Heart Stout": "6.5",
  "Black Hole": "7",
  "Black Lab Stout": "5.6",
  "Black Magick": "15",
  "Black Marlin Porter": "6",
  "Black Mo Stout": "4.5",
  "Black Mocha Stout": "5.3",
  "Black Ops": "11",
  "Black Orchard": "5.6",
  "Black Rye Bock": "6.1",
  "Black Stallion Oatmeal Stout": "4.8",
  "Black Strap Stout": "6",
  "Black Tulip": "9",
  "Black Xantus": "10",
  "Blackbeary Wheat": "4",
  "Blackberry Ale": "5.1",
  "Blackburn Doppelbock": "7.9",
  "Blackened Voodoo": "5",
  "Blackfriar": "7",
  "Blackout": "5",
  "Blackout Stout": "9",
  "Blacksburger Pils": "4.6",
  "Blackstone Stout": "5.9",
  "Blanche": "5",
  "Blanche Double": "6",
  "Blanche de Bruges": "5",
  "Blanche de Chambly": "5",
  "Blanche de Namur": "4.5",
  "Blanche des Honnelles": "6",
  "Blanche des Moines": "4.5",
  "Blast Furnace Oatmeal Stout": "4.8",
  "Blind Date Ale": "7",
  "Blind Pig Dunkelweizen": "4.6",
  "Blind Pig IPA": "6",
  "Blind Tiger Pale Ale": "4.8",
  "Blitzen Christmas Ale": "8.5",
  "Blizzard Bock": "6.0",
  "Bloesem Bink": "7.0",
  "Blond": "4.8",
  "Blonde": "7.5",
  "Blonde Bock": "7",
  "Blonde Draft": "4.8",
  "Blonde Lager": "4.5",
  "Blonde Tradition": "6.1",
  "Blue": "4.9",
  "Blue Bell Bitter": "5.4",
  "Blue Dot Double India Pale Ale": "7",
  "Blue Goat": "7.5",
  "Blue Heron Pale Ale": "4.9",
  "Blue Moon Belgian White": "5.4",
  "Blue Paddle Pilsener": "4.8",
  "Bluejackets Best": "6.2",
  "Bob's '47 Oktoberfest": "5.8",
  "Bock": "5.5",
  "Bock Beer": "5.9",
  "Bockbier": "7",
  "Bodacious Black and Tan": "7.8",
  "Bohemian Blonde": "4.6",
  "Bohemian Dock Street Pilsner": "4.8",
  "Boltwood Bock": "5.6",
  "Bombardier Premium Ale": "5.1",
  "Bombshell Barleywine": "9.8",
  "Bonaventure Pale Ale": "5.5",
  "Bond Street 19th Anniversary": "8.6",
  "Boont Amber Ale": "5.8",
  "Border Gold Oranic Ale": "6",
  "Bornem Double": "8",
  "Bornem Triple": "9",
  "Borsodi": "5",
  "Bos Keun": "8.8",
  "Bottle Conditioned Porter": "5.5",
  "Bottle Rocket IPA": "6.5",
  "Bottleworks IPA": "8.5",
  "Bouldevard ZÅŒN": "4.4",
  "Boulevard Dry Stout": "4.9",
  "Boulevard Irish Ale": "5.8",
  "Boulevard Maibock": "5.9",
  "Boulevard Pale Ale": "5.4",
  "Boulevard Unfiltered Wheat": "4.5",
  "Bourbon Barrel Aged Winter Warmer": "9.5",
  "Bourbon Barrel Porter": "9.1",
  "Bourbon Barrel Scotch Ale": "7.5",
  "Bourbon County Brand Coffee Stout": "13",
  "Bourbon County Stout": "13",
  "Bourbon County Stout 2007": "13",
  "Boys of Summer Wheat": "3.5",
  "Braggot": "6",
  "Brasserie De Brunehaut Bio BiÃ¨re Blonde (Organic)": "6.5",
  "Brasserie de Brunehaut Bio BiÃ¨re AmbrÃ©e (Organic)": "6.5",
  "Brasserie de Brunehaut Bio BiÃ¨re Blanche (Organic)": "5",
  "Braunbier": "5.6",
  "Bravo": "6.5",
  "Bravoure": "7",
  "Breakup Bock": "7",
  "Breath of the Dragon English Bitter": "4.5",
  "Brewhouse Blonde": "4.6",
  "Brewhouse Lager": "4.5",
  "Brewtality Espresso Black Bier": "9.6",
  "Breznak Dunkles/Dark": "3.7",
  "Bridgeport India Pale Ale": "5.5",
  "Brigand": "9",
  "Brigantine Smoked Porter": "8.5",
  "Bright Spot Golden Ale": "4.5",
  "Brilliant Barstool": "5.0",
  "Broad Axe Stout": "5",
  "Broad Street Barleywine": "8.6",
  "Broadside Ale": "6.3",
  "Brocken Top Bock": "6",
  "Brockton Black Lager": "6.5",
  "Broken Halo IPA": "6",
  "Broken Keg Ice Bock": "15",
  "Broken Paddle": "6",
  "Broken Spoke": "5",
  "Bronze Griffin": "6.8",
  "Broodoo": "5.5",
  "Brooklyn Lager": "5.1",
  "Brother Thelonious": "9",
  "Brown Aged Girl": "4.5",
  "Brown Ale": "5.5",
  "Brown Bird Brown Ale": "4.8",
  "Brown Shugga": "9.8",
  "Brown's Point ESB": "5.5",
  "Bruegel Amber Ale": "5.1",
  "Brueghel Blonde": "5.9",
  "Brugse Zot": "6",
  "Brugse Zot Double": "7",
  "Bruin Tradition / Brune Tradition": "8.1",
  "Brune": "9.5",
  "Brune (Brown)": "6.8",
  "Brune / Brown": "8",
  "Brune / Bruin": "7.5",
  "Brutal Bitter": "6.5",
  "Bubbly Wit": "7.0",
  "Buck Naked": "3.2",
  "Bud Dry": "5",
  "Bud Extra": "6.5",
  "Bud Ice": "5.5",
  "Bud Ice Light": "4.0",
  "Bud Light Lime": "4.1",
  "Budweiser": "5",
  "Budweiser American Ale": "5.0",
  "Budweiser Budvar (Czechvar)": "5",
  "Budweiser Select": "4.3",
  "Buffalo Belgian Stout": "9",
  "Buffalo Butt": "4.5",
  "Buffalo Gold": "6.3",
  "Buffalo Lager": "4.8",
  "Bugeater Brown Ale": "4.4",
  "Bully! Porter": "5.4",
  "Buried Hatchet Stout": "8.2",
  "Burning Bird Pale Ale": "4.8",
  "Burning River Pale Ale": "6",
  "Burns Scottish Ale": "5.3",
  "Burton Baton": "10",
  "Butthead Doppelbock": "8.1",
  "Butty Bach": "4.5",
  "Buzzard Breath (discontinued)": "5",
  "Buzzsaw Brown": "4.8",
  "CD-Pils": "4.9",
  "CH-47 Pale Ale": "5",
  "Cafe Amsterdam's 10th Anniversary Gruit": "12",
  "Caguama": "3.9",
  "Calabaza Blanca": "4.8",
  "Caldera IPA": "6.0",
  "Calico Amber Ale": "5",
  "Calico Jack Amber Ale": "5.4",
  "California Dreaming": "8",
  "California Gold": "4.5",
  "Cambonator Doppelbock": "7.1",
  "Cambridge Amber": "4.3",
  "Canadian Breakfast Stout": "9.3",
  "Canadian Lager Beer": "5",
  "Canaster Winter Scotch": "8.6",
  "Capitol Kolsch": "4.8",
  "Capitol Premium Pale Ale": "5",
  "Cappa Cappa Crusher": "4.5",
  "Cappuccino Stout": "8.3",
  "Captain Lawrence Xtra Gold": "10",
  "Captain's Reserve Imperial IPA": "9",
  "Captin Lawrence Liquid Gold": "6",
  "Captin Lawrence Pale Ale": "5.5",
  "Captin Lawrence Smoked Porter": "6.4",
  "Carling": "4.0",
  "Carnegie Stark-Porter": "5.5",
  "Cartwright Pale Ale": "4.3",
  "Cascade Apricot Ale": "9",
  "Cascade CuvÃ©e Du Jongleur": "8.4",
  "Cascade Golden Ale": "4.5",
  "Cascade IPA": "5.3",
  "Cascade Kreik Ale": "6.5",
  "Cascade Razberry Wheat": "4.5",
  "Cascade Sang Royal": "8.8",
  "Cascazilla": "7",
  "Casey Jones Imperial IPA": "7.2",
  "Cassis Reserve Lambic â€˜06": "6.5",
  "Castaway Barley Wine Winter Ale": "11",
  "Castelain Blond Biere de Garde": "5.5",
  "Castelain St.Amand French Country Ale": "5.9",
  "Catalina Common Lager": "4",
  "Catfish Cream Ale": "5",
  "Cattail Peak": "4.6",
  "Caulfield's Rye": "4.9",
  "Celebration Ale": "6.8",
  "Celebrator": "6.6",
  "Celestial Gold": "4.0",
  "Celis Grand Cru": "7.5",
  "Celis Pale Bock": "3.9",
  "Celis Raspberry": "3.9",
  "Celis White": "3.9",
  "Celtic Copper Ale": "5.1",
  "Celtic Knot Irish Red": "4.8",
  "Celtic Rose": "5",
  "Censored (aka The Kronic)": "5.9",
  "Centennial IPA": "7.1",
  "Cerberus 10 Dog Ale": "10",
  "Cerise": "6.5",
  "Certified Organic Amber Ale": "4.8",
  "Certified Organic India Pale Ale": "7",
  "Certified Organic Porter": "5.5",
  "Cerveza Diablo": "7.5",
  "Cerveza Negra": "6.5",
  "Cerveza Roja": "6",
  "Cerveza Sol": "4.5",
  "Chambly Noire": "6.1",
  "Charles River Porter": "6",
  "Charlie 1981": "8.5",
  "Chateau Jiahu": "9.5",
  "Chautauqua Brew": "4.9",
  "Chazz Cat Rye": "4.5",
  "Checker Cab Blonde Ale": "5.1",
  "Chicken Killer Barley Wine": "10",
  "Chico Estate Harvest Ale": "6.6",
  "Chicory Stout": "5.1",
  "Chief Blackhawk Porter": "5.5",
  "Chimay DorÃ©e": "4.8",
  "Chimay Grand Reserve(Chimay Blue)": "9",
  "Chimay PremiÃ¨re (Chimay Red)": "7",
  "Chimay Triple (Chimay White)": "8",
  "Chocolat": "8",
  "Chocolate Stout": "6",
  "Choklat": "11",
  "Chouffe-Bok": "6.5",
  "Christmas Ale": "8.5",
  "Christmas Bock": "6",
  "Christoffel Blond": "6",
  "Chubby Brown": "8",
  "Church Brew Oktoberfest": "5.1",
  "Cinder Cone Red": "5.4",
  "Cinq Cents (White)": "8",
  "Circus Boy": "5.1",
  "Classic Pale Ale": "5.5",
  "Classic Pilsener": "5",
  "Cliffhanger Light Ale": "3.2",
  "Climax Barleywine-Style Ale": "10",
  "Climax California Classic": "5",
  "Climax Doppel Bock": "8",
  "Climax Extra Special Bitter Ale": "5.5",
  "Climax Helles": "4.8",
  "Climax IPA": "6",
  "Climax Nut Brown Ale": "5.1",
  "Climax Oktoberfest": "6.1",
  "Climax Wheat Ale": "5",
  "Clipper Gold Hefeweizen": "5.1",
  "Club Colombia": "4.6",
  "CoHoHo Imperial IPA": "8",
  "Cobra-Hood IPA": "7.3",
  "Cocoa Loco": "7",
  "Coffee Stout": "5.6",
  "Cold Hop": "6.5",
  "Colonel Blide's Cask Ale": "5.5",
  "Colonel Paris Pale Ale": "4.5",
  "Colt 45": "6.5",
  "Columbus Pils": "4.9",
  "Come About Imperial Stout": "9",
  "Commodore Perry IPA": "7.5",
  "Conquest Ale": "9",
  "Consecrator Doppelbock Beer": "8",
  "Conspiracy": "8.5",
  "Conundrum Red Ale": "7",
  "Coopers Best Extra Stout": "6.3",
  "Coopers Mild Ale": "3.5",
  "Coopers Original Pale Ale": "4.5",
  "Coopers Premium Lager": "5",
  "Coopers Premium Light": "2.9",
  "Coopers Sparkling Ale": "5.8",
  "Coors Extra Gold Lager": "5",
  "Coors Light": "4.1",
  "Coors Original": "5",
  "Copper Hill Kolsch": "5.0",
  "Corona Extra": "4.5",
  "Corona Light": "4.0",
  "Coronator Helle Doppelbock": "7.5",
  "Cosmic Black Witbier": "6.1",
  "Cottonwood Endo India Pale Ale": "5.9",
  "Cousin Jax": "9",
  "Cow Palace Scotch Ale": "6.8",
  "Cow Palace Scotch Ale 1998": "8.6",
  "Crabby Larry's Irish Red Ale": "6",
  "Cream Ale": "4.8",
  "Cream Stout": "6.5",
  "Creamy Dark": "4.9",
  "Creme Brulee Imperial Milk Stout": "10",
  "Creme Brulee Java Stout": "5.8",
  "Cricket's Nocturne": "4.5",
  "Crooked Door": "5",
  "Crooked Tree IPA": "6",
  "Crosstown Pale Ale": "5.3",
  "Cucapa Honey Ale": "4.5",
  "Cumberland Pale Ale": "4.5",
  "Curator Dunkler Doppelbock": "9",
  "Curmudgeon": "9.3",
  "Curve Ball": "4.8",
  "Cutthroat Porter": "4.8",
  "Cypress Honey Lager": "4.6",
  "D. Carnegie and Company Porter": "5.5",
  "DPA": "5.0",
  "DSB / Dusters Special Bitter": "5.5",
  "Daas Organic Blond": "6.5",
  "Daas Organic Witte": "5",
  "Daddy Fat Sacs Imperial IPA": "7.5",
  "Dale's Pale Ale": "6.5",
  "Damnation": "7",
  "Danger Ale": "5.1",
  "Dank": "9.1",
  "Dark Ale": "4.5",
  "Dark Element": "8.7",
  "Dark Island": "4.5",
  "Dark Knight": "12",
  "Dark Lager": "6.0",
  "Dark Lord Russian Imperial Stout": "13",
  "Dark Mild": "3.5",
  "Dark Pilsener": "5.5",
  "Das Schwarze / Dark": "4.9",
  "De Horste Vermeer Traditional Dutch Ale": "8",
  "Dead Guy Ale": "6.5",
  "Dead Leaf Green": "5.6",
  "Dead Man Walkin' IPA": "6.4",
  "Dead Monk Abbey Ale": "8",
  "Dead Reckoning Porter": "5.8",
  "Decadence": "13",
  "Deep Shaft Stout": "6.1",
  "Del Rio Lager": "4",
  "Delirium Nocturnum": "9",
  "Delirium NoÃ«l": "10",
  "Delirium Tremens": "8.5",
  "Dells Chief Amber Ale": "4.5",
  "Delta Ale": "7.8",
  "Deluxe Organic Ale": "6.9",
  "Demolition": "8",
  "Denver Pale Ale / DPA": "5.4",
  "Der Weisse Bock": "8.5",
  "Derailed Black Cherry Ale": "5",
  "Descent Imperial Abbey Ale": "8.3",
  "Detonator Doppelbock": "8",
  "Devotion": "6.2",
  "Dirty Bastard Scotch Style Ale": "8.5",
  "Dirty Blond": "4.5",
  "Divine Reserve 9": "11",
  "Dixie Crystal": "10",
  "Doc's Hard Apple Cider": "4.5",
  "Doc's Pale Ale": "5.5",
  "Dock Street Amber": "6",
  "Dogma": "7.8",
  "Dogtoberfest Octoberfest": "5.3",
  "Dom Blonde KÃ¶lsch": "5",
  "Domaine DuPage French Country Ale": "5.9",
  "Don De Dieu": "9",
  "Donner Party Porter": "6.6",
  "Donovan's Red": "5.1",
  "Doppel Bock": "8.1",
  "Doppel-Hirsch Bavarian-Doppelbock": "7.1",
  "DoppelSticke": "8.5",
  "Doppelbock": "6.8",
  "Doppelbock Dunkel": "8.5",
  "Dorado Double IPA": "9.6",
  "Dort": "6.5",
  "Dortmunder Gold": "5.8",
  "Doryman's Dark Ale": "5.8",
  "Dos Equis Lager": "4.2",
  "Dos Perros": "3.5",
  "Double Bag Ale": "7.1",
  "Double Barrel Ale": "5",
  "Double Bastard Ale": "10",
  "Double Chocolate Stout": "5.1",
  "Double D IPA": "8.1",
  "Double Daddy": "9.5",
  "Double Dead Guy Ale": "9",
  "Double Diamond Winter": "8.5",
  "Double Eagle \"\"High-Test\"\" Scotch Ale\"": "8",
  "Double Eagle Scotch Ale": "5.8",
  "Double Enghien Blonde Ale": "7.5",
  "Double Enghien Bruin": "8",
  "Double IPA": "6.8",
  "Double India Pale Ale": "8.6",
  "Double Mountain Altbier": "5.5",
  "Double Mountain Kolsch": "5.1",
  "Double Mountain Pale Ale": "5.0",
  "Double Paw": "8.5",
  "Double Pilsner": "8.1",
  "Double Simcoe IPA": "9",
  "Double Trouble IPA": "9.8",
  "Double Trouble Imperial IPA": "9.3",
  "Double White": "6.5",
  "Double Wide I.P.A.": "8.5",
  "Downtown After Dark": "12",
  "Downtown Brown": "5",
  "Dragon Stout": "7.5",
  "Dragon's Milk": "9",
  "Dragonhead Stout": "4",
  "Dragonslayer": "9.5",
  "Drake's Crude": "6.8",
  "Dreadnaught Imperial IPA": "9.5",
  "Dreamweaver": "4.8",
  "Dreher Classic": "5",
  "Drie Fonteinen Kriek": "5",
  "Drop Top Amber Ale": "5",
  "Druid Fluid": "9.5",
  "Drunk Monk Hefeweizen": "4.8",
  "Dry Hop Orange": "5.4",
  "Dry Hop Red": "5.6",
  "Dry Hopped St. Rogue Red Ale": "5.0",
  "Dry-Hopped APA": "5.9",
  "Dubbel": "7",
  "Dubbel Ale": "5.1",
  "Dubbel Dragon": "7.1",
  "Dubble Fantasy": "5",
  "Dublin Stout": "6",
  "Duchesse de Bourgogne": "6.1",
  "Duchy Originals Organic English Ale": "5",
  "Duck-Rabbit Amber Ale": "5.5",
  "Duck-Rabbit Brown Ale": "5.5",
  "Duck-Rabbit Milk Stout": "5.6",
  "Duck-Rabbit Porter": "5.6",
  "Duck-Rabbit Schwarzbier": "5.8",
  "Duet": "7",
  "DuganA IPA": "8.5",
  "Duggie Meyer Wee Heavy": "7",
  "Duinen Dubbel": "8",
  "Duke IPA": "5.0",
  "Dulle Teve / Mad Bitch": "10",
  "Dundee Export 90 Scotch Ale": "6.5",
  "Dundee Festive Ale": "6.1",
  "Dundee India Pale Ale": "6.3",
  "Dundee KÃ¶lsch-Style Ale": "5.6",
  "Dundee Pale Ale": "5.3",
  "Dundee Pale Bock Lager": "6.2",
  "Dundee Porter": "6.5",
  "Dundee Scotch Ale": "6.5",
  "Dundee Stout": "5.8",
  "Dundee Wheat Beer": "4.1",
  "Dunkel": "4.8",
  "Dunkel Lager": "5",
  "Dunkle Weisse": "5.3",
  "Dunkles": "5.0",
  "Dunkles Hefe Weizen": "5.3",
  "Dusseldorfer Doppelbock": "6",
  "Dutch Girl Lager": "4.3",
  "Duvel": "8.5",
  "Duveneck's Dortmunder": "4.5",
  "Dynamo Copper Lager": "5.5",
  "E.S. Bam": "4.6",
  "EKU 28": "11",
  "EKU Pils": "4.9",
  "ESB": "6.0",
  "ESB (Extra Special Blizzard)": "6",
  "ESB - Early Spring Beer": "5.9",
  "ESB Ale": "5.5",
  "Earth - Belgian Style Chocolate Milk Stout": "5",
  "East End Witte": "4.5",
  "East India Pale Ale": "6.8",
  "East Kent IPA": "6.5",
  "Easy Street Wheat": "4.5",
  "Edel-Pils": "4.8",
  "Edel-Spezial": "5.1",
  "Edgar I.P.A.": "8",
  "Edmund Fitzgerald Porter": "5.8",
  "Eighty-Shilling Scottish Ale (80/-)": "5.3",
  "Eine Bamberger Weisse Hell": "5.1",
  "Eisbock": "9.1",
  "Eisenbahn Weizenbock": "8",
  "Eislin Dubbel": "8",
  "El Diablo Tripel": "11",
  "El Jefe": "5.6",
  "Elephant": "7.1",
  "Eliot Ness Amber Lager": "6.1",
  "Elissa IPA": "6.5",
  "Elizabethan Ale": "8.1",
  "Elm City Schwarzbier": "5.3",
  "Elysian Fields Pale Ale": "4.8",
  "Emancipator": "8.3",
  "Empire India Pale Ale": "7.5",
  "Ename Tripel": "8.5",
  "Engine 5": "5",
  "English Bay Pale Ale": "5",
  "Entire Stout": "4.5",
  "Envy": "7",
  "Epic Pale Ale": "5.4",
  "Epiphany Ale": "5.5",
  "Equinox Dark Belgian Winter": "8",
  "Erdinger Weizen": "5.3",
  "Erik the Red": "5",
  "Ertvelds Wit": "5",
  "Espresso Oak Aged Yeti": "9.5",
  "Estes Park Gold": "4.1",
  "Estes Park Porter": "4.5",
  "Estrella Damm": "4.5",
  "Estrella Levante Clasica": "4.8",
  "Estrella Levante Especial": "5.4",
  "Exit 11 - Hoppy American Wheat": "6.1",
  "Exit 16 - Wild Rice Double IPA": "8.1",
  "Export": "5.4",
  "Export Ale": "5.0",
  "Export Premium": "5.4",
  "Export-Hell": "5.3",
  "Extra Pale Ale": "5.4",
  "Extra Strong Vintage Ale": "7.5",
  "Eye of the Hawk": "8",
  "FA": "4.8",
  "Fahrwasser Fairway Pilsner": "5.1",
  "Fairfax Coffee Porter": "5.5",
  "Fairweather IPA": "6.0",
  "Falcon Lagrad Gammelbrygd": "5.5",
  "Fall Festivus Ale": "4.8",
  "Fallen Angel": "8",
  "Fallenbock": "7.8",
  "Fancy Lawnmower Beer": "4.9",
  "Fargo Brothers Hefeweizen": "5.2",
  "Farm Girl Saison": "6",
  "Farm House Ale": "6.6",
  "Farmer Jon's Oatmeal Stout": "6",
  "Farmer's Tan Imperial Pale Lager": "9",
  "Farmhouse Reserve": "6.3",
  "Farmhouse Saison": "6.1",
  "Fast Eddies Pale Ale": "5",
  "Fat Cat": "7.2",
  "Fat City Ale": "4.5",
  "Fat Dog Stout": "9",
  "Fat Squirrel Nut Brown Ale": "5.8",
  "Fauerbach Amber Lager": "4.5",
  "Feast of Fools": "5.3",
  "Featherweight Lager": "4.0",
  "Fegley's Amber Lager": "5.1",
  "Festbier": "5.5",
  "Festina PÃªche": "4.5",
  "Feuerwehrmann Schwarzbier": "5",
  "Fiesta IPA": "6.3",
  "Final Absolution": "8.5",
  "Finest Bitter": "4",
  "Fire Rock Pale Ale": "5.8",
  "Firehouse ESB": "5.5",
  "Firemans #4": "5.0",
  "Fireside Nut Brown": "4.9",
  "Firestole Pale 31": "4.5",
  "First Flight Amber Ale": "4.5",
  "Flagship Red Alt Ale": "5.1",
  "Flashback Anniversary Ale": "6.8",
  "Flat Belly American Wheat": "3.5",
  "Flat Earth Belgian-style Pale Ale": "5.1",
  "Flemish Primitive Wild Ale (Demon Fish)": "9",
  "Flemish Primitive Wild Ale (Pig Nun)": "9",
  "Flemish Primitive Wild Ale (Pin Head)": "9",
  "Flemish Primitive Wild Ale (Spoon Whacker)": "9",
  "Flemish Primitive Wild Ale (Surly Bird)": "9",
  "Flensburger Pilsner": "4.8",
  "Flower Power India Pale Ale": "8",
  "Flying Mouflan": "9.3",
  "Flywheel Bright Lager": "5",
  "Foggy Goggle Belgian White": "5.3",
  "Foreign Extra Stout": "7.5",
  "Forty-Niner Gold Lager": "4.5",
  "Foster Child Australian Lager": "4",
  "Foster's Lager Beer": "4.9",
  "Foster's Premium Ale": "5.5",
  "Foster's Special Bitter": "5.5",
  "Founders Black Rye": "6.4",
  "Founders Breakfast Stout": "8.3",
  "Founders Devil Dancer": "13",
  "Founders Imperial Stout": "10",
  "Founders Kentucky Breakfast": "10",
  "Founders Pale Ale": "5.1",
  "Founders Red's Rye": "6.4",
  "Four": "10",
  "Four Alarm Alt": "4.8",
  "Fourteen": "9.4",
  "Framboise": "6",
  "Frank Double IPA": "8.5",
  "Franziskaner Hefe-Weissbier Dunkel": "5",
  "Franziskaner Hefe-Weissbier Hell  / Franziskaner Club-Weiss": "5",
  "Fraoch Heather Ale": "5",
  "Fred": "10",
  "Free Bike Amber": "4.5",
  "Freestyle Pilsner": "5.5",
  "French Country Spring Beer": "6.5",
  "Fresh Frog Raw Hop Imperial Pale Ale": "7.8",
  "Fresh Hop Pale Ale": "6.0",
  "Fresh Hops Pale Ale": "5",
  "Frog's Hollow Double Pumpkin Ale": "8.3",
  "Frosted Frog Christmas Ale": "8.6",
  "Frosty Frog": "9",
  "Fuego del Otono": "6.0",
  "Fuel Cafe": "5.9",
  "Full Circle": "5.2",
  "Full Curl Scotch Ale": "6",
  "Full Moon Belgian White Ale": "7.9",
  "Full Moon Pale Rye Ale": "5.6",
  "Full Moon Winter Ale": "5.5",
  "Fuller's ESB": "4.5",
  "Fur Rondy": "6",
  "Furious Beer": "6",
  "Gaelic Ale": "5.8",
  "Gale Force IPA": "5.5",
  "Gambrinus": "4.5",
  "Garde Dog": "5.8",
  "Gavroche French Red Ale": "8.5",
  "Geary's Pale Ale": "4.5",
  "Geist Bock": "6.0",
  "Gemuetlichkeit Oktoberfest": "5.5",
  "General Pippo's Porter": "5.5",
  "General Washington Tavern Porter": "7",
  "Generation": "4",
  "Genesee Cream Ale": "5.1",
  "Genuine Honey Lager": "5",
  "Genuine Irish Stout": "4.0",
  "Genuine Lager": "5",
  "Genuine Pilsner": "5.5",
  "Georgi-Belgique": "6.4",
  "Georgia Brown": "5.0",
  "Geuze Boon": "6",
  "Ghost Pony Helles Lager": "5.5",
  "Ginger Tom": "4",
  "Glacier Harvest '09 Wet Hop (100 Barrel Series #28)": "6.6",
  "Glissade Golden Bock": "6.4",
  "Glockenspiel": "8",
  "Gnaw Bone Pale Ale": "5.5",
  "God Jul - Winter Ale": "8.5",
  "Gold Ale": "4.9",
  "Gold Coast Blonde Ale": "4.4",
  "Gold Rush": "4.5",
  "Gold Star Pilsner": "5.0",
  "Gold-Pils": "5.5",
  "Golden Ale": "4.6",
  "Golden Export": "5",
  "Golden Fleece Maibock": "8.5",
  "Golden Frog": "6",
  "Golden Monkey Tripel": "9.5",
  "Golden Pheasant": "4",
  "Golden Rose": "6",
  "Golden Treasure": "4.1",
  "Golden Triangle Triple": "8.5",
  "Goldstar": "4.9",
  "Golf": "5",
  "Gonzo Imperial Porter": "7.8",
  "Goofy Foot Summer Wheat": "4.8",
  "Goose Island IPA": "5.9",
  "Goose Island Imperial IPA": "9",
  "Goose Island Midway IPA": "6.8",
  "Gordon": "8.6",
  "Gordon Biersch Czech Lager": "5.5",
  "Gordon Biersch Hefeweizen": "5.5",
  "Gordon Biersch Marzen": "5.6",
  "Gordon Biersch Schwarzbier": "4.3",
  "Gose": "4.5",
  "Gouden Carolus Ambrio 1471": "8",
  "Gouden Carolus Hopsinjoor": "8",
  "Gouden Carolus Tripel": "9",
  "Goudenband 1996": "3.9",
  "Goudenband 2002": "8",
  "Gouyasse / Goliath": "9",
  "Graduation Ale": "5.5",
  "Grail Ale": "5.5",
  "Grain Belt": "4.5",
  "Grand Cru": "9.5",
  "Grand Cru 2003": "10",
  "Grand Cru 2006": "10",
  "Grand Cru Winter Reserve": "7.1",
  "Grand Cru of the Emperor": "8",
  "Grateful Red": "5",
  "Great American's Restaurant Pale Ale": "5.6",
  "Great Divide Belgica": "7.1",
  "Great Divide Dunkel Weiss": "6.4",
  "Great Divide Saison": "7.3",
  "Great Northern Porter": "5.5",
  "Greed": "5",
  "Green Flash Imperial India Pale Ale": "9",
  "Green Lakes Organic Ale": "5.1",
  "Green Monsta Ale": "7.3",
  "Green Valley Stone Mill Pale Ale": "5.5",
  "Grid Iron Amber Ale": "5.5",
  "Griffin Golden Ale": "5.1",
  "Grimbergen Blonde": "6.6",
  "Grinnin' Grizzly Spiced Ale": "5.6",
  "Grizzly Wulff Wheat": "5.3",
  "Grolsch Amber Ale": "5.3",
  "Grolsch Dunkel Weizen": "5.5",
  "Grolsch Pilsner Speciale": "5.5",
  "Grolsch Premium Pilsner": "5",
  "Grolsch Premium Weizen": "5.5",
  "Grotten Brown": "6.6",
  "Grotten Flemish Ale": "7.6",
  "Grottenbier": "6.5",
  "Grozet Gooseberry and Wheat Ale": "5",
  "Guava Grove Ale": "8",
  "Gubna Imperial IPA": "10",
  "Gueuze 1882": "5",
  "Gueuze CuvÃ©e RenÃ©": "5",
  "Gueuze-Lambic": "6.5",
  "Guinness 250th Anniversary Stout": "5",
  "Guinness Draught": "4.3",
  "Guinness Extra Stout": "5",
  "Guinness Foreign Extra": "6.5",
  "Guldenberg": "8.5",
  "Gull Classic": "5.5",
  "Gumballhead": "4.8",
  "HIPA": "6.8",
  "HSD Hicks Special Draught": "5",
  "HUB Lager": "5.0",
  "Hades": "7.3",
  "Hahn Special Vintage 2000": "8",
  "Half in the Bagpipe": "7.1",
  "Half-E-Weizen": "4.8",
  "Halligan IPA": "5.0",
  "Hammerin' Ale": "4.5",
  "Hamtramck": "4",
  "Hands Off Maibock": "8",
  "Hanf": "5",
  "Hang Ten": "10",
  "Hansa Imported Dortmunder": "4.8",
  "Hansa Pils": "4.8",
  "Harbor Light Ale": "4.8",
  "Harborfields HefeWeizen": "4.5",
  "Hardcore IPA": "9",
  "Harp Lager": "5",
  "Harpoon Ale": "5",
  "Harpoon Brown Session Ale": "4.3",
  "Harpoon IPA": "5.9",
  "Harpoon Leviathan": "10",
  "Harpoon Munich Dark": "5.5",
  "Harpoon UFO Hefeweizen": "4.8",
  "Harpoon Winter Warmer": "5.9",
  "Hart Espresso Stout": "5.5",
  "Harvest": "4.4",
  "Harvest Ale": "5.5",
  "Harvest Ale 2007": "6.6",
  "Harvest Hefeweizen": "4.9",
  "Harvest Moon Pumpkin": "5.6",
  "HarvestÃ¶r Fresh Hop Ale": "7.1",
  "Hathor Red Lager": "5.4",
  "Haupenthal Hefeweizen": "4.8",
  "Havre Stout": "4.5",
  "Haywards 2000": "7.5",
  "Haywards 5000": "7.5",
  "Haywards Black": "8",
  "Haywords 5000": "7.5",
  "Hazed & Infused Dry-Hopped Ale": "4.8",
  "Hazelnut Brown Nectar": "6",
  "Hazy Daze Hefeweizen": "5.5",
  "Headbangerz Brown Ale": "5",
  "Headless Man Amber Alt": "5",
  "Heady Topper": "8",
  "Heat Seeker Hefe": "4",
  "Heathen": "6.9",
  "Heather Ale": "5.4",
  "Heavenly Hefeweizen": "4.5",
  "Heavenly Helles": "5.3",
  "Heavy Seas Prosit! Imperial Oktoberfest Lager": "9",
  "Heavy Seas The Great Pumpkin Imperial Pumpkin Ale": "8.5",
  "Heavy Weizen Imperial Unfiltered Wheat Ale": "8",
  "Hecker Dunkel": "5.1",
  "Hedonism Ale": "7.0",
  "Hefe Weiss": "4.1",
  "Hefe Weissbier": "5.4",
  "Hefe Weizen": "4.5",
  "Hefe-Weizen": "5.1",
  "Hefeweissbier Dunkel": "5.3",
  "Hefeweizen": "5.5",
  "Heineken": "5",
  "Helios": "7.5",
  "Hell": "4.9",
  "Hell Hath No Fury Ale": "7.6",
  "Hell's Belle": "7",
  "Hellbrook": "8",
  "Heller Hound Bock Beer": "6.1",
  "Helles Bock": "6.5",
  "Helles Golden Lager": "4.6",
  "Helles Hefe Weizen": "5.3",
  "Hellrazer": "6.1",
  "Hellsmoke Porter": "5.6",
  "Hennepin Farmhouse Ale": "7.6",
  "Herb Superb": "6",
  "Hercule Stout": "9",
  "Hercules Double IPA": "9.1",
  "Heresy": "8",
  "Heritage Alt Beer": "5.4",
  "Het Kapittel Abt": "10",
  "Hibernation Ale": "8.1",
  "Hibernator": "6",
  "High Gravity Lager": "8.1",
  "High Seas IPA": "7",
  "High Tide IPA": "6.5",
  "Highlander 80/-": "5",
  "Highwater Porter": "6",
  "Highway 22 Wheat": "4.6",
  "Hinano": "5",
  "Hinterland Hefe Weizen": "5.3",
  "Hitachino Nest Celebration Ale 2006": "9",
  "Hitachino Nest Espresso Stout": "7.5",
  "Hitachino Nest Japanese Classic Ale": "7",
  "Hitachino Nest Real Ginger Brew": "7",
  "Hitachino Nest Sweet Stout": "3.5",
  "Hitachino Nest XH": "8",
  "Hite": "4.5",
  "Hitman Gold": "5.5",
  "Hobgoblin": "5.5",
  "Hocus Pocus": "4.5",
  "Hoegaarden": "4.9",
  "HofbrÃ¤u Oktoberfestbier": "6.3",
  "Hog Heaven Barleywine": "9.1",
  "Holy Cow IPA": "5.1",
  "Holy Mackerel Mack In Black": "8",
  "Holy Mackerel Special Golden Ale": "8.5",
  "Holy Moly": "7.8",
  "Holy Moses": "5.4",
  "Holy Sheet": "9",
  "Homeport Blonde": "4.5",
  "Honey Brown": "4.5",
  "Honey Brown Ale": "6",
  "Honey Bunny Blonde Ale": "5.8",
  "Honey Cream Ale": "5.5",
  "Honey Double Maibock": "7",
  "Honey Moon Summer Ale": "5.5",
  "Honey Pilsner": "4.5",
  "Honey Wheat": "4.4",
  "Honey and Ginger Ale": "4",
  "Honey-Raspberry Ale": "4.4",
  "Hooker Oktoberfest": "5.8",
  "Hop 15 Ale": "10",
  "Hop Dam Triple IPA": "10",
  "Hop Devil India Pale Ale": "6.6",
  "Hop Explosion": "7",
  "Hop Hazard": "5.5",
  "Hop Hearty IPA": "6.1",
  "Hop Hed Red Ale": "6",
  "Hop Henge Imperial IPA": "8.7",
  "Hop Hog IPA": "7.9",
  "Hop Juice Double IPA": "9.3",
  "Hop Knot IPA": "6",
  "Hop Lava": "7",
  "Hop Maniac IPA": "6.5",
  "Hop Master's Abbey Belgian-style Double IPA": "8.5",
  "Hop Mountain": "5.5",
  "Hop Obama": "5.1",
  "Hop Ottin IPA": "7",
  "Hop Rocker": "5.5",
  "Hop Rod Rye": "8",
  "Hop Stoopid": "8.1",
  "Hop Sun": "4.5",
  "Hop Trip Fresh Hop Pale Ale": "5.5",
  "Hop Wallop": "8.5",
  "HopBack Amber Ale": "5.5",
  "HopFish IPA": "6.1",
  "Hope & King Scotch Ale": "6",
  "Hopfen Weisse": "8.1",
  "Hopmouth Double IPA": "8",
  "Hopnoxxxious": "7.4",
  "Hoppe Imperial Extra Pale Ale": "8",
  "Hoppin' To Heaven IPA": "6.8",
  "Hopportunity Knocks Ale": "6.8",
  "Hoppy Chick": "5.7",
  "Hoppy Claus Holiday Ale": "7.8",
  "Hoppy Face Amber Ale": "5.5",
  "Hoppy Frog": "6.5",
  "Hoppy Trails India Pale Ale": "6.1",
  "Hops Infusion": "6.1",
  "Hopsecutioner": "7.1",
  "Hopslam Ale": "10",
  "Hoptical Illusion": "6.8",
  "Hoptimus Prime": "10",
  "Hopworks IPA": "6.5",
  "Hopyard IPA": "5.3",
  "Horizon Red Ale": "5.6",
  "Horny Devil": "10",
  "Horny Toad IPA": "7.0",
  "Horseshoe Hefeweizen": "4.5",
  "Hot Shot ESB": "5",
  "HotRod Red": "6.0",
  "Houblon": "9",
  "House Ale": "7.1",
  "Howl": "4.5",
  "Huckleberry Ale": "3.5",
  "Humbles Blond Ale": "3.5",
  "Humidor Series Jai Alai Cedar Aged India Pale Ale": "7.5",
  "Hummer": "5.0",
  "Humpis-Original Naturtrub": "5.0",
  "Huusbier Hell": "4.5",
  "Huusbier Schwarz": "4.5",
  "IC Light": "4.1",
  "IPA": "5.5",
  "Icehouse": "5.5",
  "Ichabod": "5.1",
  "Ichabod's Midnight Ride": "6",
  "Idiot IPA": "8.5",
  "Illuminator Doppelbock": "6.8",
  "Im Paled Ale": "6.5",
  "Immort Ale": "11",
  "Imperial Cherry Saison": "8",
  "Imperial Chocolate Pumpkin Porter": "7.8",
  "Imperial Eclipse Stout": "9.5",
  "Imperial Espresso Porter": "8",
  "Imperial Gold Malt Liquor": "9.8",
  "Imperial Hefeweizen": "7.5",
  "Imperial I.P.A.": "7.1",
  "Imperial India Pale Ale / I2PA": "9.5",
  "Imperial Mokah Blended Stout": "11",
  "Imperial Nut Brown": "9.8",
  "Imperial Premium Malt Pilsner": "8.8",
  "Imperial Pumpkin Ale": "8",
  "Imperial Red": "9",
  "Imperial Stout": "7",
  "Imperial Stout 2001": "9.4",
  "Imperial Stout 2002": "9.4",
  "Imperial Stout 2003": "9.3",
  "Impromptu Pale Ale": "5",
  "In-Heat Wheat": "4.6",
  "Independence Ale": "6.6",
  "India Pale Ale": "6.5",
  "India Red Ale (The \"\"IRA\"\")\"": "6.8",
  "India Style Brown Ale": "6",
  "Indian Brown Ale": "7.1",
  "Indian Red Ale": "6.5",
  "Indiana Amber": "5.5",
  "Indica India Pale Ale": "6.5",
  "Indus Pride": "4.8",
  "Industrial IPA": "7.4",
  "Iniquity Imperial Black Ale": "9",
  "Inspiration Red": "5.5",
  "Instigator Doppelbock": "7.5",
  "Inversion IPA": "6.8",
  "Iowa Pale Ale": "5",
  "Ipswich Dark Ale": "6.3",
  "Ipswich Harvest": "6.9",
  "Ipswich IPA": "6.3",
  "Ipswich Nut Brown": "5.5",
  "Ipswich Oatmeal Stout": "7",
  "Ipswich Original Ale": "5.4",
  "Ipswich Porter": "5.9",
  "Ipswich Summer": "4.9",
  "Ipswich Winter": "6.1",
  "Irish Amber": "4.5",
  "Irish Dry Stout": "4.8",
  "Irish Red": "4.4",
  "Irish Red Ale": "5.1",
  "Irish Style Ale": "5.1",
  "Iron City": "4.5",
  "Iron Hill Belgian Tripel": "9.5",
  "Iron Rail IPA": "7.1",
  "Iron Thistle": "8",
  "Ironbound Ale": "5.0",
  "Ironhead Porter Old No. 3": "6",
  "Ise Kadoya Stout": "5",
  "Island Lager": "5",
  "Island Pale Ale (IPA)": "7",
  "Islander Pale Ale": "5",
  "Isolation Ale": "6",
  "Ivanhoe": "5.1",
  "Jack of Spades Schwarzbier": "3.9",
  "Jack's Pumpkin Spice Ale": "5.5",
  "Jacobite Ale": "8",
  "Jacobsen Dark Lager": "5.8",
  "Jade": "4.5",
  "Jahrhundert-Bier": "5.5",
  "Jahva Imperial Coffee Stout": "12",
  "Jai Alai IPA": "7.5",
  "James Squire Australian Best Ale Limited Release": "6.8",
  "Jan de Lichte": "7",
  "Jasparilla": "9",
  "Java Head Stout": "7.5",
  "Java Stout": "7.5",
  "Jenlain Blonde": "7.5",
  "Jenlain St Druon de Sebourg": "6",
  "Jeremiah Red": "7.3",
  "Jever Pilsener": "4.9",
  "Jinx": "6.9",
  "Jittery Frog": "6",
  "JoBoy's IPA": "6.5",
  "John Barleycorn Barleywine Style Ale": "9",
  "Johnston Pilsener": "4.9",
  "Jolly Roger": "9",
  "Jolly Roger Imperial Stout": "9",
  "Jolly Scot Scottish Ale": "5.1",
  "JuJu Ginger": "4",
  "Jubelale": "6.6",
  "JuleÃ¸l": "5.9",
  "Juniper Pale Ale": "5.3",
  "Jupiler": "5.1",
  "K-9 Cruiser Winter Ale": "6.4",
  "K-O Blond Beer": "10",
  "Kalamazoo Stout": "6",
  "Kapuziner Kristall-Weizen": "5.4",
  "Kapuziner Schwarz-Weizen": "5.4",
  "Karma": "5.1",
  "Kashmir IPA": "5.5",
  "Kasteel Bier Brune": "11",
  "Kasteel Bier Donker-Foncee": "11",
  "Kasteel Blonde": "11",
  "Kataja Olut IVB": "7",
  "Kate The Great Russian Imperial Stout": "9.5",
  "Kellerbier": "5",
  "Kells Irish Style Lager": "5",
  "Kelpie Seaweed Ale": "4.4",
  "Kent Lake Kolsch": "4",
  "Kenziger": "4.5",
  "Kerberos": "8.5",
  "Kerplunk Imperial Chocolate Stout": "8.1",
  "Kerst Pater Special Christmas Beer": "9",
  "Keystone Light": "4.1",
  "Killer Penguin": "10",
  "Killian's Irish Red": "4.9",
  "Kilt Kicker Wee Heavy": "7",
  "Kilt Lifter  Scottish-Style Ale": "6",
  "Kilt Lifter Scottish Ale": "8",
  "Kilt Tilter": "9",
  "King Crimson": "6",
  "King Lager": "5.1",
  "King's Peak Porter": "4",
  "Kingfisher Premium": "4.8",
  "Kings Pilsener": "5",
  "Kipona Fest": "5.1",
  "Kirin Ichiban": "4.9",
  "Kirin Light": "3.3",
  "Kitsilano Maple Cream Ale": "5",
  "Knock Out": "8",
  "Kodiak Brown Nut Brown Ale": "5",
  "Konig Ludwig Weissbier": "5.5",
  "Korbinian": "7.4",
  "Koshihikari Echigo Beer": "5",
  "Kozel Premium": "5",
  "Krampus Imperial Helles Lager": "9",
  "Kriek": "7",
  "Kristall Weissbier": "5.4",
  "Kristall Weizen": "5.3",
  "Krooks Mill": "5.3",
  "Kryptonite Imperial IPA": "9",
  "Krystal Weizen": "4.9",
  "LTD 02 Lager": "6.4",
  "La Chouffe Golden Ale": "8",
  "La Divine Double Blond": "7.5",
  "La Divine Tripel Amber": "9",
  "La Fin Du Monde": "9",
  "La Folie": "6",
  "La Folie Falling Rock": "6",
  "La Maitresse du Moine": "9",
  "La Moneuse": "8",
  "La Quintine Blonde": "8",
  "La Roja": "7.1",
  "La Rossa": "7.1",
  "La Trappe Dubbel": "6.5",
  "La Trappe Enkel / La Trappe Blond": "6.5",
  "La Trappe Quadrupel 2000/2001": "10",
  "La Trappe Tripel": "8",
  "Labatt 50": "5",
  "Labatt Bleue Dry": "6.0",
  "Labatt Blue": "4.6",
  "Labatt Blue Light": "4",
  "Labatt Crystal": "5",
  "Labatt Extra Dry": "5.5",
  "Labatt Ice": "5.5",
  "Labatt Sterling": "4",
  "Labatt Wildcat": "4.9",
  "Labrador Lager": "6",
  "Lager": "4",
  "Lager Beer": "4.8",
  "Lagerbier": "5.5",
  "Lagunitas IPA": "5.7",
  "Lake Placid Frostbite Ale": "6.8",
  "Lancaster Hefe Weizen": "6",
  "Lancaster Milk Stout": "5.3",
  "Lancaster Oktoberfest": "6.5",
  "Lancaster Strawberry Wheat": "4.6",
  "Lancaster Winter Warmer": "8.8",
  "Landshark Lager": "4.6",
  "Lap Dance Pale Ale": "5.5",
  "Late Harvest Fest Lager": "6.1",
  "Laughing Dragon": "6.2",
  "Lava Rock Porter": "7",
  "Lawnmower": "4",
  "Le Freak Belgian Style IPA": "9",
  "LeBleu": "5",
  "LeRoy's Brown Ale": "5.3",
  "Leffe Blonde": "6.5",
  "Left Hand Milk Stout": "5.9",
  "Legbiter Ale": "4.6",
  "Leinenkugel's Berry Weiss": "4.6",
  "Leinenkugel's Honey Weiss": "4.9",
  "Leinenkugel's Summer Shandy": "4.1",
  "Leinenkugel's Sunset Wheat": "4.9",
  "Les Sans Culottes": "7",
  "Leute Bok Bier": "7.5",
  "Leviathan 2004": "10",
  "Levitation Ale": "4.4",
  "Levity Amber Ale": "5.0",
  "Lewmeister Oktoberfest": "5.4",
  "Lia Fail Stone of Destiny Ale": "4.6",
  "Liberation Ale": "6",
  "Liberty Ale": "6",
  "Liefmans Kriekbier": "6",
  "Lift Bridge Pale Ale": "5.5",
  "Light Ale": "4",
  "Light Lager": "3.5",
  "Light Weight": "4.3",
  "Lighthouse Ale": "4.3",
  "Lindener Spezial": "5.0",
  "Lion Stout": "8",
  "Lionshead": "4.5",
  "Liquid Sunshine Blonde Ale": "5",
  "Little Creatures Pilsner": "4.5",
  "Little Scrapper IPA": "6",
  "Loakal Red": "6.9",
  "Local 1": "9",
  "Local 2": "8.6",
  "Locke Mountain Light": "3.7",
  "Loki": "4.8",
  "London Porter": "6.5",
  "London Pride": "4.6",
  "Lone Star": "4.6",
  "Lone Star Light": "3.8",
  "Long Island Potato Stout": "3.9",
  "Long Trail Ale": "4.5",
  "Long Trail Hefeweizen": "5",
  "Long's Peak Raspberry Wheat": "4.1",
  "Longboard Lager": "5.5",
  "Loose Cannon Hop3 Ale": "7.2",
  "Lord Chesterfield Ale": "5.4",
  "Lost Sailor": "7",
  "Lost and Found Abbey Ale": "7.5",
  "Loterbol": "8",
  "Lovitz Lager \"\"Watermelon Lager\"\"\"": "4.5",
  "Loyal Duke Scotch Ale": "8.6",
  "Lozen Boer Abt": "10",
  "Lucifer": "8.5",
  "LuciÃ©rnaga": "6.5",
  "Lucky 13 Anniversary Release": "8.3",
  "Lucky 13 Mondo Large Red Ale": "8.3",
  "Lucky Kat": "5.8",
  "Lucky U Denver Special Bitter": "4.4",
  "Lucky U ESB": "5.0",
  "Ludwig's Revenge": "5",
  "Luksusa": "5.1",
  "Lump of Coal Dark Holiday Stout": "8",
  "Luna Weizen": "4.5",
  "Lunar Ale": "5.0",
  "Luxus Pils": "4.9",
  "MAI-BOCK": "7.5",
  "Mackeson XXX Stout": "4.9",
  "Mackinac Pale Ale": "5.5",
  "Mad Anthony's Ale": "5.5",
  "Mad Brewer Maibock": "7",
  "Mad Hatter IPA": "5.8",
  "Madrugada Obscura": "8.1",
  "Maduro": "6.4",
  "Maduro Oatmeal Brown Ale": "5.5",
  "Maibark": "6.5",
  "Maibock": "7.1",
  "Maillot Jaune": "5.5",
  "Maisel's Weisse Kristall": "5.0",
  "Malheur 10": "10",
  "Malheur 12": "12",
  "Malheur Black Chocolate 2003": "12",
  "Malheur Brut Reserve": "11",
  "Malheur MM": "10",
  "Malteser Weissbier": "5",
  "Mama's Little Yella Pils": "5.3",
  "Mamma Mia! Pizza Beer": "4.5",
  "Mammoth Extra Stout": "6",
  "Manayunk Lager": "6.4",
  "Manchester Star Ale": "7.3",
  "Manheim Red": "5.2",
  "Manifesto Pale Ale": "5.3",
  "Maple City Gold": "7.1",
  "Maracaibo Especial": "7.5",
  "Marble India Pale Ale": "6.1",
  "Maredsous 10 Tripple": "10",
  "Marina Light Lager": "4.6",
  "Mars - Belgian Imperial Red IPA": "8.6",
  "Marshal Zhukov's Imperial Stout": "11",
  "Martin's Pale Ale": "5.8",
  "Marzen": "5.6",
  "Marzoni's Amber Lager": "6.0",
  "Masala Mama IPA": "5.9",
  "Massatucky Brown": "5.2",
  "Master Brew Bitter": "4",
  "Matilda": "7",
  "Maudite": "8",
  "Maui Coconut Porter": "5.6",
  "Mavericks Amber Ale": "4.8",
  "Maximus": "7.5",
  "Maximus Ale": "8.3",
  "Mayhem Belgian-style Double IPA": "8.1",
  "McChouffe": "8.5",
  "McGilligans IPA": "6.2",
  "Mean Manalishi Double I.P.A.": "8.1",
  "Meantime Coffee": "6",
  "Meister Pilsener": "5.0",
  "Meltdown Double IPA": "8",
  "Melting Pot Pale Ale": "5.5",
  "Menage Frog": "9",
  "Mercury - Belgian Style Small Beer": "6.5",
  "Meridian Street Premium Lager": "4.8",
  "Merry Monks": "9.3",
  "Mestreechs Aajt": "3.5",
  "Michelob Amber Bock": "5.1",
  "Michelob Beer": "5",
  "Michelob Black & Tan": "5",
  "Michelob Dunkel Weisse": "5.5",
  "Michelob Honey Lager": "4.9",
  "Michelob Hop Hound Amber Wheat": "5.3",
  "Michelob Light": "4.3",
  "Michelob Ultra": "4.1",
  "Michelob Ultra Amber": "5",
  "Michelob Winter's Bourbon Cask Ale": "6",
  "Mickey's Ice": "5.8",
  "Midas Touch Golden Elixir": "9",
  "Middle Ages Apricot Ale": "4.5",
  "Middle Ages Raspberry Ale": "4.5",
  "Midnight Sun Kolsch": "5",
  "Midnight Wit": "4.8",
  "Midwest IPA": "7.1",
  "Millennium Brew": "10",
  "Millennium Lager": "5",
  "Millennium Trippel": "9",
  "Miller Chill": "4.0",
  "Miller Genuine Draft": "4.6",
  "Miller Genuine Draft 64": "2.7",
  "Miller High Life": "5",
  "Miller Lite": "4.1",
  "Milwaukee's Best Light": "4.5",
  "Milwaukees Best": "4.5",
  "Mirror Mirror": "11",
  "Mirrorpond Pale Ale": "5",
  "Misery Bay IPA": "6.5",
  "Misfit Red": "5.0",
  "Mississippi Mud Black & Tan": "5",
  "MoJo India Pale Ale": "6.8",
  "Mocha Joe": "8.1",
  "Mocha Porter / New Porter": "5.3",
  "Mocny BOSS / BOSS Beer": "8.1",
  "Model-A-Tor Doppelbock": "7.4",
  "Modelo Lager": "4.4",
  "Modus Hoperandi": "6.8",
  "Mogul Ale": "6.2",
  "Moinette Blonde": "8.5",
  "Moinette Brune": "8.5",
  "Mojo Risin' Double IPA": "10",
  "Mokah Imperial Blended Stout": "11",
  "Molson Canadian": "5",
  "Mongo Double IPA": "8.5",
  "Monk Madness Ale": "7.4",
  "Monk's Cafe": "5.5",
  "Monkey Wrench Dark Ale": "5.3",
  "MonkeyBoy Ale": "8.1",
  "Mont St. Aubert": "8",
  "Montagnarde": "9",
  "Monteith's Celtic Beer": "4.4",
  "Monteith's Summer Ale": "5",
  "Moonglow Weizenbock": "8.6",
  "Moonraker": "7.5",
  "Moose Drool Brown Ale": "5.3",
  "Moosehead Lager": "5",
  "Morimoto Imperial Pilsner": "8.8",
  "Morocco Ale": "5.5",
  "Mort Subite Gueuze Lambic": "4.5",
  "Mortal Sin": "6.6",
  "Mortality Stout": "7.5",
  "Mothership Wit": "4.8",
  "Mountain Lager": "4.5",
  "Moylander Double IPA": "8.5",
  "Moylans Hopsickle Imperial Ale": "9.1",
  "Mt. Nittany Pale Ale": "5",
  "Mt. Takhoma Blonde Ale": "3.7",
  "Mt.Tam Pale Ale": "5.8",
  "Mullin Brown": "4.8",
  "Muse Farmhouse Ale": "6.1",
  "Mythos Hellenic Lager": "5",
  "MÃ¶nchshof Premium Schwarzbier": "4.9",
  "MÃ¼nchner Hell / Premium Lager": "5.1",
  "N.W. Pale Ale": "5",
  "Nagelweiss": "5.0",
  "Natural Ice": "5.9",
  "Natural Light": "4.1",
  "Naturtrubes": "5.0",
  "Navigator Doppelbock": "8.8",
  "Nectar IPA": "6.8",
  "Negra": "5.4",
  "Negra Modelo": "6",
  "Nelson": "7",
  "Never Summer Ale": "5.9",
  "New": "4.5",
  "New Belgium Trippel Belgian Style Ale": "8.5",
  "Newbold IPA": "6.5",
  "Newcastle Brown Ale": "4.6",
  "Newport Storm Hurricane Amber Ale": "5.1",
  "Nickelbier": "6.5",
  "Nieuw Ligt Grand Cru 2006": "12",
  "Nightmare": "5",
  "Nightwatch Dark Ale": "5.5",
  "Nine Men Ale": "4.3",
  "Nit Wit": "4.6",
  "No Doubt Stout": "5.7",
  "Noblesse": "6",
  "Nocturn": "9",
  "Noel de Calabaza": "9",
  "Nor' Easter": "12",
  "Nora": "7",
  "North Pleasant Pale Ale": "5.2",
  "North Star Red": "5.8",
  "North of the Border Porter": "6",
  "Northern Light Lager": "4.4",
  "Northwind Imperial Stout": "7.5",
  "Nostradamus": "9.5",
  "Not Tonight Honey Porter": "6.5",
  "Nugget Nectar": "7.5",
  "Nut Brown": "4.3",
  "Nut Brown Ale": "4.1",
  "Nut Cracker Ale": "5.9",
  "Nutty Brewnette": "5.9",
  "NÃ¸gne Ã˜ #100": "10",
  "NÃ¸gne Ã˜ Amber Ale": "6",
  "NÃ¸gne Ã˜ Bitter": "4.5",
  "NÃ¸gne Ã˜ Brown Ale": "4.5",
  "NÃ¸gne Ã˜ Doppelt IPA": "8",
  "NÃ¸gne Ã˜ Imperial Brown Ale": "7.5",
  "NÃ¸gne Ã˜ Imperial Stout": "9",
  "NÃ¸gne Ã˜ India Pale Ale": "6.5",
  "NÃ¸gne Ã˜ Pale Ale": "6",
  "NÃ¸gne Ã˜ Porter": "7",
  "NÃ¸gne Ã˜ Saison": "6.5",
  "NÃ¸gne Ã˜ Wit": "4.5",
  "O! Gold Light Beer": "3.7",
  "O'Hara's Celtic Stout": "4.3",
  "OB Lager": "4.4",
  "OSB Premium Ale": "4.5",
  "OVL Stout": "4",
  "Oak Aged Ebenezer": "9.3",
  "Oak Aged IPA": "6",
  "Oak Aged Unearthly Imperial Pale Ale": "11",
  "Oak Aged Yeti Imperial Stout": "9.5",
  "Oak Barrel Stout": "5.1",
  "Oak Creek Amber Ale": "5.5",
  "Oak-Aged Belgian Tripel": "9",
  "Oak-Aged Imperial Chocolate Pumpkin Porter": "7.8",
  "Oaked Arrogant Bastard Ale": "7.1",
  "Oasis": "7.1",
  "Oatmeal Porter": "5.8",
  "Oatmeal Stout": "5",
  "Oberon Ale": "5.8",
  "Obliteration I": "8.5",
  "Obliteration II": "8.3",
  "Obliteration III": "7.5",
  "Obliteration IV": "8.6",
  "Obliteration V": "8.1",
  "Obliteration VI": "8.5",
  "Obovoid Empirical Stout": "8",
  "Obsidian Stout": "6.4",
  "Octoberfest Beer": "5.5",
  "Odd Notion Spring 08": "4.5",
  "Odd Notion Summer 08": "3.5",
  "Odd Notion Winter 07": "7.5",
  "Odd Notion Winter 08": "7.5",
  "Odell IPA": "7",
  "Odell Red Ale": "6.5",
  "Oderbolz Bock": "6.5",
  "Oerbier": "7.5",
  "Ohio City Oatmeal Stout": "4.8",
  "Okocim Porter": "8.3",
  "OktoberFish": "5.5",
  "Oktoberfest": "5.0",
  "Oktoberfest Weizen": "5.6",
  "Old '99 Barley Wine": "11",
  "Old 95": "7",
  "Old Abominable Barley Wine": "9",
  "Old Bawdy 2006": "10",
  "Old Blarney Barleywine": "10",
  "Old Boardhead 2006": "9",
  "Old Bridge Dark Lager": "5.3",
  "Old Brown Dog Ale": "5.9",
  "Old BullDog Extra Special": "5.8",
  "Old Cedar": "7",
  "Old Chub": "8",
  "Old Engine Oil": "6",
  "Old Engine Oil Special Reserve (aged in malt whisky casks)": "8",
  "Old Foghorn 1996": "10",
  "Old Foghorn 1998": "10",
  "Old Foghorn 2001": "10",
  "Old Foghorn 2002": "10",
  "Old Foghorn 2006": "9.3",
  "Old Growler": "6.5",
  "Old Guardian Barley Wine 2003": "9.9",
  "Old Heathen": "8",
  "Old Hooky": "4.5",
  "Old Jubilation Ale": "8",
  "Old Knucklehead 2000": "9.1",
  "Old Knucklehead 2003": "9.1",
  "Old Legover": "4.0",
  "Old Man Ale": "4.8",
  "Old Man Winter Ale": "8",
  "Old Marcus Ale": "6.5",
  "Old Mill Pond ESB": "6.5",
  "Old Milwaukee": "4.5",
  "Old Nick": "7.1",
  "Old Nick Pale Ale": "5.5",
  "Old No.38 Stout": "5.5",
  "Old Numbskull 2003": "10",
  "Old Peculier": "5.6",
  "Old Princeton Landing IPA": "6.0",
  "Old Rasputin Russian Imperial Stout": "9",
  "Old Redwood Porter": "5.5",
  "Old Rogue Pale Ale": "5.5",
  "Old Salt IPA": "5.8",
  "Old Scratch Lager": "5.5",
  "Old Seattle Lager": "4.3",
  "Old Slug Porter": "4.5",
  "Old Slugger Pale Ale": "5.5",
  "Old Speckled Hen": "5.1",
  "Old Tom Barley Wine": "8.5",
  "Old Walt Smoked Wit": "5",
  "Old Whiskers": "4.5",
  "Old Woody 2005": "10",
  "Old Wylie's IPA": "6.1",
  "Old-Style Porter": "5.0",
  "Olde #8 Anniversary Ale": "6.8",
  "Olde Buzzard Lager": "5",
  "Olde St.Nick": "9.8",
  "Ondineke Oilsjtersen Tripel": "8.5",
  "One": "8",
  "One Nut Brown Ale": "5",
  "Onward Stout": "3.7",
  "Oosik Amber Ale": "5",
  "Optimator": "7.1",
  "Orange Blossom Pilsner": "6.5",
  "Orange Blossom Pilsner Â²": "11",
  "Orchard White": "5.6",
  "Organic Ale": "5",
  "Organic Amber Ale": "5",
  "Organic Barley Wine Ale 2005": "9.5",
  "Organic Gingerbread Ale": "6.8",
  "Organic India Pale Ale": "6.6",
  "Original": "5.1",
  "Original Lager": "5.0",
  "Original White Ale": "5",
  "Orlio Common Ale": "5",
  "Orlio Seasonal Black Lager": "4.5",
  "Orlio Seasonal India Pale Ale": "5.4",
  "Oro de Calabaza": "8",
  "Orthus Belgian Dubbel": "7",
  "Orval Trappist Ale": "6.9",
  "Otis Alt": "5.3",
  "Ottakringer Helles": "5.0",
  "Otter Creek Imperial India Pale Ale": "11",
  "Otto's Hefeweizen": "7.0",
  "Otto's Weizenbock": "7.1",
  "Oude Geuze": "6",
  "Outta Kilter Wee-Heavy Scotch Red Ale": "8.1",
  "Oyster Point Oyster Stout": "5.9",
  "Ozzy": "7.2",
  "P.M. Porter": "6.4",
  "PENNdemonium": "8",
  "PILZILLA": "6.7",
  "Pabst Blue Ribbon": "4.7",
  "Pabst Blue Ribbon Light": "4.1",
  "Pacific Coast Ale": "5.5",
  "PacÃ­fico Clara": "4.5",
  "Padawan Pale Ale": "5",
  "Paddle Out Stout": "5.1",
  "Paddywhack IPA": "6.5",
  "Pale Ale": "5.5",
  "Pale Pale Boss Ale": "4.8",
  "Palm Speciale": "5.1",
  "Palo Santo Marron": "12",
  "Pannepot": "10",
  "Panty Peeler Tripel": "8.5",
  "Pappy's Porter": "5.4",
  "Paracelsus Zwickl": "5",
  "Paradise Pale Ale": "4.8",
  "Paradise Porter": "4.9",
  "Paradox Speyside": "10",
  "Paris-Roubaix Pale Ale": "5.2",
  "Parking Violation": "5.5",
  "Partly Sunny": "5",
  "Partridge In A Pear Tree": "12",
  "Patchway Pale Ale": "5.5",
  "Pater 6": "6.6",
  "Patriot Pale Ale": "5.1",
  "Patriots Pilsner": "4.8",
  "Paulaner Oktoberfest": "6",
  "Pauwel Kwak": "8",
  "Pawn Shop Porter": "5.6",
  "Pays du Soleil": "8",
  "Peacemaker Porter": "5.5",
  "Peg Leg Stout": "8",
  "Pelican Pale": "5",
  "Penalty Shot Porter": "5.8",
  "Pendle Witches Brew": "5.0",
  "Peninsula Porter": "5",
  "Penn Dark": "4",
  "Penn Gold": "4",
  "Penn Marzen": "4.5",
  "Penn Oktoberfest": "4.5",
  "Penn Pilsner": "4",
  "Penn Weizen": "4",
  "Pennypacker Porter": "5.4",
  "Peregrine Pilsner": "4.8",
  "Perfect Stout": "4.8",
  "Peroni": "5.0",
  "Peroni Nastro Azzurro": "5.0",
  "Perseus Porter": "5.4",
  "Pete's Wicked Wanderlust Cream Ale": "5.5",
  "Petite Orval": "3.5",
  "Petrus Aged Pale": "7.3",
  "Petrus Blond Ale": "6.5",
  "Petrus Dubbel Bruin Ale": "6.5",
  "Petrus Gouden Tripel Ale": "7.5",
  "Petrus Oud Bruin": "5.5",
  "Petrus Speciale": "5.5",
  "Phin & Matt's Extroidinary Ale": "5.5",
  "Phoenix": "5",
  "Phoenix Pale Ale": "5.5",
  "Phoenixx Double ESB": "7.1",
  "Phuket Lager": "5",
  "Pick Axe IPA": "6.5",
  "Pickled Santa": "6",
  "Piels Draft": "4.5",
  "Piels Light": "3.7",
  "Pig Pen Pilsener": "4.6",
  "Piketown Pils": "5.0",
  "Pilgrim's Dole": "10",
  "Pillar Point Pale Ale": "4.5",
  "Pilot Rock Porter": "6",
  "Pils": "5",
  "Pils Legende": "4.9",
  "Pilsener": "4.8",
  "Pilsener Bier": "5",
  "Pilsener Lager": "5.5",
  "Pilsner": "5.3",
  "Pilsner Urquell": "4.4",
  "Pine Belt Pale Ale": "6.3",
  "Pinnacle Peak Pale Ale": "4.1",
  "Pioneer Peak Porter": "4.3",
  "Pious Monk Dunkel": "5.1",
  "Pipe Organ Pale Ale": "4.9",
  "Piranha Pale Ale": "5.6",
  "Pirate's Porter": "5.3",
  "Piston Bitter": "5",
  "Pitchfork Rebellious Bitter": "4.3",
  "Piva Bohemia": "4.6",
  "Plaid Bastard": "8",
  "Planet Bean Coffee Stout": "7",
  "Planet Porter / Boulder Porter": "5.5",
  "Plead the 5th Imperial Stout": "12",
  "Pleasanton Pale": "5.3",
  "Pliny the Elder": "8",
  "Pliny the Younger": "11",
  "Pluto - Belgian-style Golden Strong Ale": "8.6",
  "Point Defiance IPA": "5.5",
  "Pomegranate Wheat": "4.6",
  "Poor Richard's Tavern Spruce Ale": "5",
  "Poperings Hommel Ale": "7.5",
  "Port Panzer Imperial Pilsner": "9.5",
  "Portage Bay Pilsener": "5.5",
  "Porter": "8",
  "Porter Boom": "8",
  "Porter Czarny Boss / Black BOSS Porter": "9.6",
  "Porter IV": "7.1",
  "Porteris": "7",
  "Ports Pale Ale": "4.3",
  "Portsmouth Lager": "5.0",
  "Possession Porter": "5.5",
  "Potrero ESB": "5.1",
  "Pozharnik": "8.1",
  "PranQster Belgian Ale": "7.5",
  "Premium": "4.5",
  "Premium Beer": "4.8",
  "Premium Lager": "5",
  "Premium Light Lager": "4.5",
  "Premium Pils": "4.8",
  "Premium Pils Edelherb": "4.9",
  "Premium Pilsener": "4.9",
  "Premium XB Bluebird Bitter": "4.4",
  "Presque Isle Pilsner": "4.1",
  "Pride": "6.5",
  "Pride of Milford Special Ale": "7.6",
  "Pride of Romsey IPA": "5",
  "Prima Pils": "5.3",
  "Princess of Darkness Porter": "4.5",
  "Prior 8": "8",
  "Prize Old Ale": "9",
  "Proletary": "5",
  "Proper Job": "5.5",
  "Prophecy": "9.3",
  "Puffers Smoked Porter": "4.5",
  "Puget Brown Porter": "5.5",
  "Pugsley's Signature Series Barley Wine Style Ale": "8.5",
  "Pugsley's Signature Series Imperial Porter": "7.0",
  "Pugsley's Signature Series XXXX IPA": "9.2",
  "Pumking Imperial Pumpkin Ale": "9",
  "Pumpkin Ale": "5.1",
  "Pumpkinhead Ale": "4.5",
  "Punk IPA": "6",
  "Punkin Ale": "7",
  "Pure Hoppiness": "8",
  "Purist Pale Ale": "4.9",
  "Purple Nightie": "6.1",
  "Pyramid Amber Weizen": "5.5",
  "Pyramid Apricot Weizen": "5.0",
  "Pyramid Broken Rake": "6.0",
  "Pyramid Hefe Weizen": "5.1",
  "Pyramid Thunderhead IPA": "6.6",
  "Quadrupel": "10",
  "Quail Springs IPA": "6",
  "Quelque Chose": "8",
  "RUMBAH Double Rum Bock": "10",
  "Racer 5 IPA": "7",
  "Racer X": "7.8",
  "Rader Blonde": "6.5",
  "Raftman": "5.5",
  "Raging Bitch Belgian IPA": "8.3",
  "Rahr's Bucking Bock": "8",
  "Rahr's Oktoberfest": "5.5",
  "Rahr's Red": "4.5",
  "Rahr's Summertime Wheat": "5.5",
  "Rahr's Winter Warmer": "9",
  "Railbender Ale": "6.8",
  "Railyard Ale": "5.1",
  "Raison D'Etre": "8",
  "Ramrod Special Bitter Ale": "5",
  "Ramstein Winter Wheat": "9.5",
  "Ranger India Pale Ale": "6.5",
  "Rare Rooster Summer Rye Ale": "4.8",
  "Rare Vos": "6.5",
  "Rasberry Ale": "3.9",
  "Raspberry Brown Ale": "4.9",
  "Raspberry Imperial Stout": "8",
  "Raspberry Wheat": "4.5",
  "Ratskeller": "4.9",
  "Rauchbier Lager": "4.5",
  "Rauchbock": "7",
  "Razz-Wheat": "5",
  "Red & White": "10",
  "Red Ale": "5.5",
  "Red Barn Ale": "6.6",
  "Red Biddy / Real Biddy": "4.9",
  "Red Brick Double Chocolate Oatmeal Porter": "7.6",
  "Red Card Lager": "6.6",
  "Red Chair IPA": "6.4",
  "Red Coat Ale": "5.6",
  "Red Dog": "5",
  "Red Jack": "5",
  "Red Lager": "4.5",
  "Red MacGregor": "5",
  "Red Mo Ale": "5",
  "Red Nectar": "5",
  "Red Racer India Pale Ale": "6.5",
  "Red Rocket Ale": "6.8",
  "Red Seal Ale": "5.5",
  "Red Sky at Night": "7.2",
  "Red Stripe": "4.7",
  "RedLegg Ale": "4.9",
  "Redback Original Malt Wheat Beer": "4.6",
  "Redhook Long Hammer IPA": "6.5",
  "Redwing": "4.7",
  "Refreshing Summer Ale": "3.9",
  "Regatta Golden": "4.1",
  "Regiment Pale Ale": "6.0",
  "Reinaert Flemish Wild Ale": "9",
  "Renegade IPA": "5.8",
  "Restoration Pale Ale": "5",
  "Resurrection": "7",
  "Revitalization Rye": "6.5",
  "Richbrau Abbey-Style Ale": "7",
  "Richbrau India Pale Ale": "6.1",
  "Richbrau Oatmeal Stout": "5.1",
  "Ridge Runner Barley Wine": "7.5",
  "Ridgeline Amber": "5.4",
  "Riggwelter Yorkshire Ale": "5.6",
  "Righteous Ale": "7.1",
  "Righteous Red": "4.5",
  "Ring Tail Pale Ale": "4.1",
  "Rio Blanco Pale Ale": "5.3",
  "Riot Ale": "7.5",
  "Rip Tide": "8",
  "Ripon Jewel Ale": "5.8",
  "Rising Moon Spring Ale": "5.4",
  "River Horse Lager": "5",
  "River Horse Special Ale": "4.5",
  "Road Dog Ale": "6",
  "Road Trip Hard Cider": "6.4",
  "Robert the Bruce Scottish Ale": "7",
  "Robertus": "6",
  "Robust Porter": "5.4",
  "Rochefort 6": "7.5",
  "Rochefort 8": "9.1",
  "Rock Art American Red Ale": "4.8",
  "Rocket's Red Ale": "6",
  "Rockslide IPA": "6.6",
  "Rocktoberfest": "5",
  "Rocky's Revenge": "6",
  "Rodenbach": "5.0",
  "Roggen Bock": "5.5",
  "Roggenbier": "5.5",
  "Rogue Smoke": "6",
  "Roland's ESB": "5",
  "Rolling Rock": "4.5",
  "Romakloster": "4.5",
  "Rondy Brew 2009": "4.5",
  "Rose": "7.5",
  "Rost": "5",
  "Rotgold-Pils": "4.9",
  "Roundabout Oatmeal Stout": "6",
  "Route 113 IPA": "7.1",
  "Rowhouse Red": "5",
  "Roxy Rolles": "5.8",
  "Royal Challenge": "5",
  "Royal Weisse": "5.6",
  "Rubaeus": "7",
  "Ruben & The Jets": "8.6",
  "Rubia": "6",
  "Rugged Trail Ale": "4.4",
  "Ruination IPA": "7.6",
  "Russian Imperial Stout": "9.5",
  "Russian River IPA": "6.5",
  "Rust Belt Porter": "4.0",
  "Ruth": "4.5",
  "Rycerskie": "7",
  "Rye IPA": "6.8",
  "Rye On": "4.8",
  "Rye Squared": "9.5",
  "Sacramento Bock": "6.5",
  "Sacramento Nut Brown Ale": "5.4",
  "Sagebrush Stout": "6.0",
  "Saint Arnold Amber Ale": "5.5",
  "Saint Arnold Brown Ale": "5.3",
  "Saint Arnold Spring Bock": "6.4",
  "Saint Arnold Texas Weat": "3.9",
  "Saison": "5.6",
  "Saison 1900": "5.1",
  "Saison De Brooklyn": "6.8",
  "Saison De Lente": "6.5",
  "Saison Imperiale": "8.5",
  "Saison Rue": "8.5",
  "Saison Vielle Provision": "6.5",
  "Saison Voisin": "5",
  "Salmon Bay E.S.B.": "5.5",
  "Salvation": "9",
  "Salvator": "7.9",
  "Samichlaus Bier 2003": "14",
  "Samichlaus Bier 2005": "14",
  "Samichlaus Bier 2006": "14",
  "Samson Crystal Lager Beer": "5",
  "Samson Stout": "5",
  "Samuel Adams Black Lager": "4.9",
  "Samuel Adams Blackberry Witbier": "5.5",
  "Samuel Adams Boston Ale": "4.9",
  "Samuel Adams Boston Lager": "4.7",
  "Samuel Adams Brown Ale": "5.3",
  "Samuel Adams Chocolate Bock": "5.5",
  "Samuel Adams Coastal Wheat": "5.3",
  "Samuel Adams Cranberry Lambic": "5.9",
  "Samuel Adams Double Bock": "8.8",
  "Samuel Adams Hallertau Imperial Pilsner": "8.8",
  "Samuel Adams Holiday Porter": "5.8",
  "Samuel Adams Imperial Stout": "9.1",
  "Samuel Adams Noble Pils": "5.1",
  "Samuel Adams OctoberFest": "5.6",
  "Samuel Adams Old Fezziwig Ale": "5.9",
  "Samuel Adams Pale Ale": "5.2",
  "Samuel Adams Scotch Ale": "5.4",
  "Samuel Adams Summer Ale": "5.1",
  "Samuel Adams Winter Lager": "5.8",
  "Samurai": "5.0",
  "San Miguel 1516": "4.1",
  "Sandy Beach Blonde Hefeweizen": "4.5",
  "Santa Fe Nut Brown": "5.1",
  "Santa Fe Stout": "5",
  "Santa Fe Wheat": "5.2",
  "Santa's Little Helper Witbier": "5.6",
  "Santas Private Reserve": "6",
  "Sante Fe Pale Ale": "5.5",
  "Sara Buckwheat Ale": "6",
  "Saranac Adirondack Lager": "5.5",
  "Saranac Belgian Ale": "5.9",
  "Saranac Belgian White": "5.3",
  "Saranac Black & Tan": "5.0",
  "Saranac Black Diamond Bock": "6",
  "Saranac Black Forest": "5.3",
  "Saranac Bock": "6",
  "Saranac Brown Ale": "6",
  "Saranac Caramel Porter": "5.4",
  "Saranac Chocolate Amber": "5.5",
  "Saranac Extra Special Bitter": "5.5",
  "Saranac Golden Pilsner": "5.0",
  "Saranac Hefeweizen": "5.5",
  "Saranac Imperial IPA": "8.5",
  "Saranac Imperial Stout": "9",
  "Saranac India Pale Ale": "5.8",
  "Saranac Kolsch": "5",
  "Saranac Lager": "4.8",
  "Saranac Marzenbier": "5.5",
  "Saranac Mocha Stout": "5",
  "Saranac Mountain Ale": "5",
  "Saranac Nut Brown Ale": "5.4",
  "Saranac Oatmeal Stout": "4.3",
  "Saranac Octoberfest": "5.4",
  "Saranac Pale Ale": "5.5",
  "Saranac Pumpkin Ale": "5.4",
  "Saranac Rachbier": "5.5",
  "Saranac Scotch Ale": "6",
  "Saranac Season's Best": "5.3",
  "Saranac Single Malt": "5.5",
  "Saranac Summer Ale": "4.6",
  "Saranac Winter Wassail": "5.8",
  "Satan Gold": "8",
  "Satan Red": "8",
  "Satellite Stout": "5.5",
  "Satin Solstice Imperial Stout": "7.5",
  "Satsuma Harvest Wit": "5.0",
  "Saturn - Belgian-style Fresh Hop IPA": "8",
  "Savannah Fest": "4.9",
  "Saxo": "8",
  "Scaldis / Bush Amber 12%": "12",
  "Scaldis Prestige": "13",
  "Scape Goat Pale Ale": "4.6",
  "Scarlet Lady Ale": "5",
  "Scharer's Lager": "5",
  "Schlafly Barleywine": "11",
  "Schlafly Oktoberfest": "5.0",
  "Schlappeseppl Export": "5.5",
  "Schlenkerla Helles Lagerbier": "4.3",
  "Schmitz Pale Ale No. 5": "4.5",
  "Schneider Weisse": "5.4",
  "Schuylkill Punch": "6",
  "Schwarz Bier": "4.8",
  "Schwarzbier": "4.3",
  "Schwarzbier / Dunkel": "4.9",
  "Schwarze Weisse": "5.1",
  "SchÃ¶fferhofer Hefeweizen Hell": "5",
  "Scotch": "8",
  "Scotch Ale": "6",
  "Scotch brand Ale": "7.1",
  "Scottish Ale": "4.8",
  "Scratch #11 2008": "4.9",
  "Scratch #12 2008": "6.5",
  "Scratch #13 2008": "4.9",
  "Scratch #14 2008": "6.6",
  "Scratch #15 2008": "6",
  "Scratch #16 2008": "6.3",
  "Scratch #17 2009": "5.5",
  "Scratch #18 2009": "7.3",
  "Scratch #19 2009 Imperial Double Expresso Oatmeal Stout": "9.5",
  "Scratch #20 2009 Apollo Imperial Ale": "7",
  "Scratch #21 2009 - Artisan Ale": "7.8",
  "Scratch #22 2009": "5.5",
  "Scratch #23 2009 Kellar Fest": "5.0",
  "Scratch #24 2009 Van de Hoorn": "7.8",
  "Scratch #25 2009 Magical Moustache Rye": "6.5",
  "Scratch #27 2010 Cocaoabunga": "6.6",
  "Scratch #6 2007": "5.0",
  "Scratch #7 2008": "7.9",
  "Scratch Beer 31 - 2010 Citra Of Brotherly Love IPA": "7.4",
  "Scrimshaw Pilsner Style Beer": "4.4",
  "Sculpin India Pale Ale": "7",
  "Sea Dog Apricot Wheat": "3.5",
  "Sea Dog Blue Paw Wheat": "3.5",
  "Sea Dog Raspberry Wheat Ale": "4.5",
  "Seamus Irish Red": "5.5",
  "Sebewaing Beer": "5",
  "Secession Black India Pale Ale (CDA)": "6.5",
  "Second Street IPA": "6",
  "Seigneuriale": "7.5",
  "Serafijn Christmas Angel": "8",
  "Seriously Bad Elf": "9",
  "Session Premium Lager": "5.0",
  "Seven Gates Pale Ale": "5.5",
  "Sexual Chocolate": "10",
  "Shakespeare Stout": "6",
  "Shantytown Doppelbock": "6.5",
  "Shark Attack Double Red Ale": "9",
  "Shelter Pale Ale": "5",
  "Shepard's Crook Scotish Ale": "5.0",
  "Sherwood Forest IPA": "6",
  "Shiner Blonde": "4.4",
  "Shiner Bock": "4.4",
  "Shiner Bohemian Black Lager": "4.9",
  "Shiner Hefeweizen": "5.4",
  "Shining Star Pale Ale": "5",
  "Shiva IPA": "5.3",
  "Shoals Pale Ale": "4.6",
  "Shock Top": "5.1",
  "Shoo-Fly Porter": "6",
  "Shouboushi Ginger Pilsner": "5.0",
  "Shut The Hell Up": "3.2",
  "Siberian Night Imperial Stout": "7.5",
  "Sick Duck Imperial Stout": "10",
  "Sierra Nevada Pale Ale": "5.5",
  "Silk Lady": "4.4",
  "Silk Porter": "6.1",
  "Silver": "5.0",
  "Simcoe Single Hop IPA": "6.9",
  "Single Chair Ale": "5",
  "Single-Wide I.P.A.": "5.6",
  "Singletrack Copper Ale": "5.5",
  "Sirius": "7",
  "Six Rivers IPA": "7.1",
  "Skagit Brown Ale": "4.8",
  "Skibbereen Stout": "5.3",
  "Skinny Dip": "4.1",
  "Skopsko": "5",
  "Skullsplitter Ale": "8.5",
  "Sky Pilot Scotch Ale": "6.5",
  "Skyline Stout": "4.3",
  "Slaapmutske Triple Nightcap": "8.1",
  "Slab Cabin IPA": "5",
  "Slam Dunkelweizen": "8",
  "Slip Knot Imperial IPA 2006": "7.8",
  "Sly Fox Christmas Ale": "6.5",
  "Sly Rye Porter": "5.6",
  "Small Beer": "3.2",
  "Small Craft Warning": "7.2",
  "Smashing Berry Ale": "4.3",
  "Smithwick's": "4.5",
  "Smoked Porter": "5.9",
  "Smooth Hoperator": "7",
  "Smuttynose Finestkind IPA": "5.5",
  "Smuttynose Pumpkin Ale": "5.0",
  "Smuttynose Winter Ale": "4.8",
  "Snake Dog IPA": "7.0",
  "Snake Oil": "7",
  "Snake Pit Porter": "5.5",
  "Sneck Lifter": "5.0",
  "Snow Bound Winter Ale": "8.6",
  "Snow Cap": "7",
  "Sockeye Red IPA": "5.6",
  "Sophie's Sparkling Ale": "4.3",
  "Soproni": "5",
  "Sour Monkey": "9.5",
  "South County American Ale": "6.3",
  "South Hampton IPA": "6.1",
  "South Park Blonde": "5",
  "Southern Hemisphere Harvest Fresh Hop  Ale": "6.6",
  "Southern Pecan": "4.2",
  "Southern Tier Harvest Ale": "5.6",
  "Southern Tier IPA": "6.5",
  "Southern Tier Porter": "5.1",
  "Southern Tier Tripel": "9",
  "Special Amber": "5",
  "Special Double Cream Stout": "6.0",
  "Special Extra Export Stout": "9",
  "Special London Ale": "6.4",
  "Specialty Beer: Black Double IPA": "8.5",
  "Specialty Beer: Oak-Aged Black Double IPA": "8.5",
  "Speedway Stout": "12",
  "Speight's Gold Medal Ale": "4",
  "Spezial": "5.0",
  "Spiced Pumpkin Ale": "5",
  "Spitfire Best Bitter": "4",
  "Spitfire Premium Kentish Strong Ale": "4.5",
  "Spotted Cow": "4.8",
  "Sprecher Abbey Triple": "8.4",
  "Sprecher Doppelbock": "7.8",
  "Sprecher Irish Stout": "5.7",
  "Sprecher Maibock": "6",
  "Sprecher Oktoberfest": "5.7",
  "Sprecher Pub Ale": "4.5",
  "Sprecher Russian Imperial Stout": "8.5",
  "Spring Bock": "6.5",
  "Spring Creek Lager": "5.1",
  "Spring Fling": "6",
  "Spring Heat Spiced Wheat": "5.1",
  "Spruce Creek Lager": "4.8",
  "Squall IPA": "9",
  "St Ides": "8",
  "St Patrick's Ale": "6",
  "St Peter's Organic Ale": "4.5",
  "St-Ambroise Apricot Wheat Ale": "5",
  "St-Ambroise Oatmeal Stout": "5",
  "St-Ambroise Pale Ale": "5",
  "St. Andrews Ale": "4.5",
  "St. Bernardus Tripel": "8",
  "St. Bernardus Witbier": "5.5",
  "St. Boisterous Hellerbock": "7.3",
  "St. Bridget's Porter": "5.9",
  "St. Charles ESB": "4.5",
  "St. Edmund English Ale": "5.5",
  "St. Louis Gueuze": "4.5",
  "St. Martin Doppelbock": "7.5",
  "St. Nikolaus Bock Bier": "6",
  "St. Patrick's Best": "4.1",
  "St. Patrick's Gold": "4.8",
  "St. Sebastiaan Golden": "7.6",
  "St. Victorious": "7.5",
  "St.Terese's Pale Ale": "5.1",
  "Staggering Elk": "4.5",
  "Starfire Pale Ale": "6.1",
  "Staropramen": "5",
  "State Pen Porter": "6.4",
  "State Street Oatmeal Stout": "6.0",
  "Steamer Glide Stout": "4.8",
  "Steamroller Stout": "6",
  "Steamwhistle Lager": "5",
  "Steel Toe Stout": "5.4",
  "Steelgaarden Wit": "5.1",
  "Steinworthy Oktoberfest": "6",
  "Stella Artois": "5.1",
  "Sticke": "6",
  "Stiegl GoldbrÃ¤u": "4.9",
  "Stiegl Leicht": "3.2",
  "Stille Nacht": "12",
  "Stinger Wild Honey Wheat": "6",
  "Stite Golden Pilsner": "4.6",
  "Stockyard Oatmeal Stout": "6",
  "Stone 09.09.09 Vertical Epic Ale": "8.8",
  "Stone 10th Anniversery IPA": "10",
  "Stone 11th Anniversery Ale": "8.6",
  "Stone 12 Anniversery Bitter Chocolate Oatmeal Stout": "9.1",
  "Stone Cali-Belgique": "6.9",
  "Stone IPA": "6.9",
  "Stone Mason Stout": "6.0",
  "Stone Pale Ale": "5.4",
  "Stone Teepee Pale Ale": "5.7",
  "Stony Face Red Ale": "5.5",
  "Storm Cellar Porter": "5.6",
  "Storm King Imperial Stout": "9.1",
  "Storm Super Premium Malt Liquor": "10",
  "Stormcould IPA": "6",
  "Stoudt's American Pale Ale": "5",
  "Stoudt's Double India Pale Ale": "10",
  "Stoudt's Gold Lager": "4.6",
  "Stoudt's Pils": "4.6",
  "Stout": "5",
  "Strange Ghost": "8",
  "Straub": "4.3",
  "Straub Light": "3.1",
  "Strike Out Stout": "4.5",
  "Strom Bomb Stout": "7.3",
  "Strong Arm Ale": "9",
  "Strong Scotch Ale": "8.6",
  "Strongbow Cider": "5.3",
  "Stud Service Stout": "3.0",
  "Sublimely Self-Righteous Ale": "8.6",
  "Sully's Irish Stout": "4.1",
  "Summer Blonde": "4",
  "Summer Gold": "5",
  "Summer Lightning": "5",
  "Summer Session": "5",
  "Summer Solstice Cerveza Crema": "5.5",
  "Summer Teeth": "5.3",
  "Summerfest": "5",
  "Sun Dog Amber Wheat": "5.3",
  "SunRay Wheat Beer": "4.6",
  "Sunbru KÃ¶lsch Style Ale": "5.1",
  "Sundog": "5.2",
  "Sunrise Weissbier": "5.6",
  "Sunset Amber Ale": "5",
  "Sunset Amber Lager": "5",
  "Sunset Red Ale": "5",
  "Sunshie Wit": "4",
  "Sunshine Pils": "5.3",
  "Sunshine Wheat": "4.8",
  "Super": "8",
  "Super Natural Oatmeal Stout": "8",
  "SuperBock": "5",
  "Superhop Ale": "5.5",
  "Superior Stout": "5.1",
  "Superstition Pale Ale": "5",
  "Supplication": "7",
  "Survival Stout": "5.3",
  "Susquehanna Oatmeal Stout": "6.1",
  "Susquehanna Stout": "4.5",
  "Swallow Wit": "4.8",
  "Swamp Fox IPA": "7",
  "Sweaty Betty Blonde": "5.9",
  "Sweet Action": "5.1",
  "Sweet Spot Hefe": "5.5",
  "Syracuse Pale Ale": "5",
  "Tacoma Brew": "5.0",
  "Taddy Porter": "5",
  "Taieri George": "6.8",
  "Tailgate Amber Wave": "6.1",
  "Tailgate Brown Ale": "5.9",
  "Tailgate Hefeweizen": "5",
  "Tailgate IPA": "6.5",
  "Tailgate Light": "4.6",
  "Tailgate Sweet Stout": "6.5",
  "Tall Tale Pale Ale": "4.9",
  "Tannen Bomb": "8",
  "Tanners Jack": "4.4",
  "Tap Room No. 21 Amber Ale": "5.6",
  "Tatonka Stout": "8.5",
  "Ten Fidy": "10",
  "Ten Thousand Brew Ale": "10",
  "Tenth Anniversery Ale": "10",
  "Terminator Stout": "6.4",
  "Terrapin All-American Imperial Pilsner": "7.5",
  "Terrapin Coffee Oatmeal Imperial Stout": "8.6",
  "Terrapin Golden Ale": "5",
  "Terrapin Midnight Project Depth Charge Espresso Milk Stout": "8",
  "Terrapin Monk's Revenge": "9.8",
  "Terrapin Rye Pale Ale": "5.5",
  "Thames Welsh Ale": "6",
  "The Abyss": "11",
  "The Bitter End Pale Ale": "5.0",
  "The Captains Porter": "6",
  "The Duke of Winship": "6.5",
  "The Hairy Eyeball": "8.8",
  "The Immortal IPA": "6.3",
  "The Iron Tankard": "9.3",
  "The Livery Dunkel Weizen": "5.2",
  "The Livery Hefe Weizen": "5",
  "The Livery Kilt Tilter": "9",
  "The Mad Elf": "11",
  "The Physics": "5",
  "The Poet": "6.5",
  "The Public": "6",
  "The Raj India Pale Ale": "6.9",
  "The Reverend\"": "10",
  "The Unforgiven Amber Ale": "5.8",
  "The Vermonster": "10",
  "The Viking Belgian-style Dark Strong Ale": "12",
  "The Wise ESB": "5.9",
  "Theobroma": "9",
  "Third Coast Beer": "4.8",
  "Thomas Hooker Irish Red Ale": "5.3",
  "Thomas Jefferson's Tavern Ale": "8",
  "Thompson Pale Ale": "5.5",
  "Thoms Special Ale": "6.2",
  "Three Guy Off The Scale Barley Wine": "15",
  "Three Philosophers": "9.8",
  "Three Steve Stout": "5.3",
  "Throwback Lager": "5.4",
  "Thumbsucker": "7.8",
  "Thunder Head IPA": "6.6",
  "Thunder Hole Ale": "4.8",
  "Thunder Storm": "5",
  "Thunder Stout": "4.9",
  "Thunderhead Amber Ale": "6",
  "Thunderhead IPA": "5.8",
  "Tikka Gold": "5",
  "Tilburg's Dutch Brown Ale": "5",
  "Tin Lizzie Hefeweizen": "5.5",
  "Titan IPA": "6.8",
  "Toasted Lager": "5.3",
  "Toaster Imperial Stout": "10",
  "Toil & Trubbel Dubbel": "8.1",
  "Tokyo": "12",
  "Toleration": "4.4",
  "Tongerlo Christmas": "6.5",
  "Tongerlo Dubbel  Blond": "6",
  "Tongerlo Dubbel Bruin": "6",
  "Tongerlo Tripple": "8",
  "Torpedo Extra IPA": "7.1",
  "Torrey Pines IPA": "6.8",
  "Total Eclipse Black Ale": "5.5",
  "Totally Naked": "4.1",
  "Tournay Black": "7.5",
  "Tracktown IPA": "7",
  "Trade Winds Tripel": "8",
  "Traditional Ale": "5",
  "Traditional Brackett": "7",
  "Traditional IPA": "5.9",
  "Trafalgar IPA": "6",
  "Trail Blaze Organic Brown Ale": "5.3",
  "Trail Ridge Red": "4.5",
  "Trappist Blond": "8",
  "Trappist Extra": "9.5",
  "Trappist Westvleteren 8": "8",
  "Trappist Westvleteren Blonde": "5.8",
  "Trashy Blonde": "4.0",
  "Tres Blueberry Stout": "4.5",
  "Tribute Premium Cornish Ale": "4.1",
  "Trickster Belgian-style Pumpkin Ale": "7",
  "Trifecta Belgian Style Tripel": "8.1",
  "Tripel": "10",
  "Tripel Ale": "9.6",
  "Tripel Crown": "10",
  "Tripel Horse": "10",
  "Tripel Krullekop": "9.8",
  "Tripel Reserve": "9",
  "Triple": "9",
  "Triple Dipsea Belgian": "9.1",
  "Triple Exultation Old Ale": "9.6",
  "Triple Karmeliet": "8",
  "Triple Moine": "7.1",
  "Triumphator": "7.5",
  "Troegenator Double Bock": "8.1",
  "Troegs Pale Ale": "5.4",
  "Trois Pistoles": "9",
  "Trompe La Mort": "7.4",
  "Tropical Mango": "4.5",
  "Troubadour": "6.5",
  "Troublette": "5.5",
  "Trout Slayer": "4.6",
  "Truly Honest Ale": "6",
  "TrÃ¶egs Splinter Beer Gold": "12",
  "TrÃ¶egs Splinter Beer Red": "11",
  "Tumbleweed IPA": "5.6",
  "Twelve Days": "5.5",
  "Twilight Ale": "5",
  "Twisted Kilt Scotch Ale": "5",
  "Twisted Thistle India Pale Ale": "6.0",
  "Two Front Teeth Holiday Ale": "9.3",
  "Two Guns Pilsner": "5",
  "Two Hearted Ale": "7",
  "Two Sisters Imperial Stout": "6.6",
  "Tyskie Gronie": "5.6",
  "UFO Raspberry Hefeweizen": "4.8",
  "Uff-Da Bock": "6.7",
  "Ugly American": "7.8",
  "Ugly Pug": "4.5",
  "Ultrablonde": "8",
  "Ultrabrune": "10",
  "Undercover Investigation Shut-Down Ale": "9.2",
  "Unearthly Imperial India Pale Ale": "11",
  "Unfiltered Double Simcoe IPA": "9",
  "Unfiltered Wheat Beer": "4.4",
  "Ungespundet Lager Hefetrub": "5.1",
  "Unicorn Amber Ale": "4",
  "Union Jack India Pale Ale": "7.5",
  "Untouchable Pale Ale": "5.5",
  "Upland Wheat Ale": "4.5",
  "Ur-Bock Hell": "6.5",
  "Ur-Pils": "5.0",
  "Ur-Weizen": "5",
  "Uranus - 100% Brettanomyces Golden Ale": "7.1",
  "Urban Wilderness English Pale": "5.0",
  "Urbock 23Â°": "9.6",
  "Urthel Hibernius Quentum Ale": "9",
  "Urthel Hop-It": "9.5",
  "Urthel Novicius Vertus": "5.9",
  "Urthel Tonicum Finiboldhus": "7.5",
  "Urthel Vlaemse Bock": "7.5",
  "V-12": "12",
  "V-Saison": "7.5",
  "Valley Berry Wheat": "4",
  "Valley Brew Pale Wheat": "4",
  "Valley Brewing Apricot Ale": "4",
  "Valley Brewing London Tavern Ale": "4.4",
  "Van den Vern Grand Cru": "9.8",
  "Vanilla Java Porter": "6",
  "Velvet ESB": "5.1",
  "Venom": "5.5",
  "Venus - Belgian-style Quadrupel": "16",
  "Verchuosity": "12",
  "Vertical Epic 04.04.04": "8.5",
  "Vertical Epic 05.05.05": "8.5",
  "Vertical Epic 07.07.07": "8.3",
  "Vertical Epic 10.10.10": "9.5",
  "Very Bad Elf Special Reserve Ale": "7.5",
  "Very Special Pale Golden Ale": "5.9",
  "Vichtenaar": "5.0",
  "Victorian IPA": "6.1",
  "Victory Ale": "5.1",
  "Victory Lager": "4.8",
  "Vienna Red Lager": "5",
  "Vigneronne": "5",
  "Village Nut Brown Ale": "6",
  "Vintage Ale 1999": "8.5",
  "Vintage Ale 2008": "8.5",
  "Viszolay Belgian": "7.5",
  "Vivant Tripel": "9.2",
  "Vlas Kop": "5.5",
  "Volks Weizenbock": "7.1",
  "Voodoo Vator": "9.5",
  "Vrak": "5.4",
  "Wacko": "4.5",
  "Wahoo Wheat Beer": "4",
  "Wailing Wench": "8",
  "WaldschlÃ¶sschen Dunkel": "5",
  "Walt Wit": "5",
  "Wapiti Amber Ale": "5",
  "Warka Strong": "7",
  "Warm Welcome Nut Browned Ale": "6",
  "Warminster Special Bitter": "3.7",
  "Warrior IPA": "6.5",
  "Warsteiner Premium Dunkel": "4.8",
  "Warsteiner Premium Oktoberfest": "5.9",
  "Warsteiner Premium Verum": "4.8",
  "Wasatch 1st Amendment Lager": "4",
  "Water Gap Wheat": "4.5",
  "Watermelon Wheat": "5.5",
  "Watershed IPA": "7.0",
  "Watou Tripel": "7.5",
  "Wayan": "5.5",
  "Weathertop Doppelbock": "7.3",
  "Wee Beast": "7",
  "Wee Heavier": "9.5",
  "Wee Heavy": "9.5",
  "Weissbier": "4.3",
  "Weissbier Hell": "5.1",
  "Weisse": "4.9",
  "Weisse Hefe-Weizen": "5.4",
  "Weizen": "5",
  "Weizen Bam BiÃ¨re": "4.5",
  "Weizen Bock": "10",
  "Weizengold Hefefein": "5.0",
  "Weizentrumpf": "5.5",
  "Weizla Hell": "5",
  "Weldspatter IPA": "7",
  "Wells Banana Bread Beer": "5.1",
  "Wells Bombardier English Bitter": "5.1",
  "WernesgrÃ¼ner Pils Legende": "4.9",
  "West Bank Pub Ale": "4.5",
  "West Coast IPA": "7",
  "Westmalle Trappist Dubbel": "7",
  "Westmalle Trappist Tripel": "9.5",
  "Wet Hop Requiem IPA": "5.1",
  "Wexford Irish Cream Ale": "5",
  "Weyerbacher Fireside Ale": "7.5",
  "Weyerbacher India": "7.1",
  "Weyerbacher Juliet": "8.5",
  "Wheatland Wheat": "5",
  "Wheatley Hills Weizenbock": "7.5",
  "Whirlwind Wit": "5",
  "Whistling Pig Hefeweizen": "4.5",
  "White Ale": "4.8",
  "White Christmas": "6.5",
  "White Horse Hefeweizen": "4.6",
  "White Magick of the Sun": "6.7",
  "Whitetail Golden Ale": "5",
  "Whose Ear? Red Ale": "4",
  "Whoville Weizenbock": "6.5",
  "Widdershins Barleywine": "9.1",
  "Widmer Hefeweizen": "4.9",
  "Widmer W'08": "4.9",
  "Wiesen Edel Weisse": "6.1",
  "Wild Banshee Barleywine": "9.8",
  "Wild Blue Blueberry Lager": "8",
  "Wild Brunette": "7.5",
  "Wild Country Raspberry Wheat": "4.1",
  "Wild Devil Ale": "6.6",
  "Wild Frog Wheat": "6",
  "Wild Goose Pumpkin Patch": "6",
  "Wild Raspberry Ale": "5.5",
  "Wild Salmon Pale Ale": "4",
  "Wild Wacky Wit": "4.8",
  "Willy's Oompa-Loompa": "6",
  "Wilsteraner Altbier": "5.5",
  "Winter Ale": "5.9",
  "Winter Brew": "5.7",
  "Winter Storm": "7.5",
  "Winter Warmer": "5.1",
  "Winter Welcome 2007-2008": "6",
  "Winter Welcome 2008-2009": "6",
  "Winter Wheat": "6.3",
  "Winter White Ale": "5",
  "Winter-Traum": "5.4",
  "WinterFish Ale": "7.5",
  "Winterfestival": "5.5",
  "Winterkoninkse": "8.3",
  "Wipeout IPA": "7",
  "Witbier": "5",
  "Witchtree ESB": "5.5",
  "Witkap-Pater Tripel": "7.5",
  "Witte": "5",
  "Wittekerke": "5",
  "Wixa Weiss": "4.8",
  "Wizard's Winter Ale": "6.8",
  "Woodchuck Amber Draft Cider": "5",
  "Woodchuck Dark and Dry Draft Cider": "5",
  "Woodchuck Granny Smith Varietal Draft Cider": "5",
  "Woody Creek White": "4.8",
  "Woody Organic IPA": "6.1",
  "Woody's Light": "3.5",
  "Workingman's Wheat": "4.9",
  "Wrath": "8.1",
  "Wynona's Big Brown Ale": "7.3",
  "X Extra Pale Ale": "5",
  "XS Imperial Stout": "10",
  "XTra": "4.5",
  "XX Bitter": "6",
  "XXX English Ale": "6.5",
  "Xingu": "4.6",
  "Yakima Glory": "8.6",
  "Yakima Twilight": "8.8",
  "Yards Brawler": "4.3",
  "Yards Extra Special Ale": "6",
  "Yards India Pale Ale": "7",
  "Yards Love Stout": "4.5",
  "Yards Philadelphia Pale Ale": "4.5",
  "Yards Saison": "4.6",
  "Yellowjacket Pale Ale": "4.5",
  "Yellowtail Pale Ale": "4.5",
  "Yeti": "8",
  "Yeti Imperial Stout": "9.5",
  "Yeti Special Export": "7",
  "Yorkshire Brown": "5",
  "Yorkshire Stout": "4.5",
  "Yorkshire Terrier Premium Cask Bitter": "4.1",
  "Young's Winter Warmer": "5.1",
  "Yuengling Black and Tan": "4.6",
  "Yuengling Lager": "4.4",
  "Yuengling Lager Light": "3.4",
  "Yuengling Porter": "4.6",
  "Yuengling Premium Beer": "4.4",
  "Yuengling Premium Light": "3.5",
  "Yule Tide": "10",
  "Z-U-Later Doppelbock": "8",
  "Zatte Amsterdamse Tripel": "8",
  "Zelta": "5",
  "Zephyrus Pilsner": "4.6",
  "Zima Clear": "5",
  "Zinnebir Xmas": "6.5",
  "Zoetzuur Flemish Ale": "7",
  "Zoigl Star Lager": "5.9",
  "Zone 7 Porter": "3.7",
  "Zwergla": "6",
  "embrasse": "9",
  "stella": "4.5",
  "Ãœber Pilsner": "5.1",
  "Ãœber Sun Imperial Summer Wheat Ale": "8",
  "Ã‰phÃ©mÃ¨re Pomme": "5.5"
}

export default beerList
