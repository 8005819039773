import React, { useState, useEffect, useCallback } from "react"
import ClearButton from "./SubComponents/ClearButton"
import Loading from "./SubComponents/Loading"
import Popup from "./SubComponents/Popup"
import TextSearch from "./SubComponents/TextSearch"
import "./App.css"
import beerList from "./db/beerList.js"
import responseRepo from "./lib/responseRepo"
import pubSoundsMp3 from "./audio/pub-sounds.mp3"
import rockMp3 from "./audio/rock.mp3"

// pub sounds
const pubSounds = new Audio(pubSoundsMp3)
pubSounds.volume = 0.9
pubSounds.addEventListener("ended", function() {
  this.currentTime = 0
  this.play()
}, false)

// rock music
const rock = new Audio(rockMp3)
rock.addEventListener("ended", function() {
  this.currentTime = 0
  this.play()
}, false)
rock.volume = 0.2

const beerListAsArray = Object.keys(beerList)

function App() {
  /** STATE */
  const [isLoading, setIsLoading] = useState(false)
  const [inputValues, setInputValues] = useState({
    brand: "", quantity: ""
  })
  const [message, setMessage] = useState("")
  const [warn, setWarn] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [muteAudio, setMuteAudio] = useState(true)

  /** HANDLERS */
  const handleOnChange = event => {
    const { name, value } = event.target
    setInputValues({ ...inputValues, [name]: value })
  }

  const handleInfoClick = () => {
    setShowPopup(!showPopup)
  }

  const handleInputClear = event => {
    const inputId = event.target.getAttribute("inputid")
    document.getElementById(inputId).value = "";
    document.getElementById(inputId).focus();
    setInputValues({ ...inputValues, [inputId]: "" })
  }

  const handleMuteClick = () => {
    if (muteAudio) {
      pubSounds.play()
      rock.play()
    } else {
      pubSounds.pause()
      rock.pause()
    }
    setMuteAudio(!muteAudio)
  }

  /** HELPERS */
  const calculateBudLightValue = useCallback((brandABV, budLightABV) => {
    const quantity = inputValues.quantity
    const budLightMultiplier = brandABV / budLightABV
    return Math.floor(quantity * budLightMultiplier * 100) / 100
  }, [inputValues.quantity])
  
  const generateMessage = useCallback(() => {
    const quantity = inputValues.quantity
    const brand = inputValues.brand
    const brandABV = parseFloat(beerList[inputValues.brand])
    const budLightABV = parseFloat("4.1")
    const budLightValue = calculateBudLightValue(brandABV, budLightABV)
    const quantityIsValid = quantity >= 0 && quantity !== "" && !isNaN(budLightValue)
  
    if (brand && quantityIsValid) {
      const messagePrefix = getResponse(budLightValue)
      const modifier1a = brandABV === budLightABV ? "and" : "while"
      const modifier1b = brandABV === budLightABV ? "also" : ""
      const modifier1c = brandABV === budLightABV ? "the same as" : "like"
      const modifier2 = brandABV > budLightABV ? "only" : ""
      const ABVMessage = `${brand} has ${brandABV}% ABV, ${modifier1a} Bud Light ${modifier1b} has ${modifier2} 4.1% ABV.`
      const standardMessage = `You had ${quantity} of them. Thats ${modifier1c} drinking ${budLightValue} Bud Lights!`
      setIsLoading(true)
      setTimeout(() => {
        setIsLoading(false)
      }, 1600)
      setWarn(false)
      return quantity === "0" ? messagePrefix : `${messagePrefix}<br/><br/>${ABVMessage}<br/></br>${standardMessage}`
    } else if (brand) {
      if (quantity < 0) {
        setWarn(true)
        return "Please enter a number greater than zero"
      } else if (isNaN(quantity) && quantity !== "") {
        setWarn(true)
        return "Please enter a valid number"
      } else {
        setWarn(false)
        return "Enter how many you had to see your results"
      }
    } else if (!brand && quantity) {
      setWarn(true)
      return "Search for the brand you drank to see your results"
    }  else {
      setWarn(false)
      return "Please search for the type of beer you drank and how many you had"
    }
  }, [calculateBudLightValue, inputValues.brand, inputValues.quantity])

  const getResponse = (num) => {
    let key = null
    if (num === 0) {
      key = "none"
    } else if (num > 0 && num <= 10) {
      key = "small"
    } else if (num > 10 && num <= 20) {
      key = "medium"
    } else if (num > 20 && num <=30) {
      key = "large"
    } else if (num > 30 && num <= 50) {
      key = "x-large"
    } else if (num > 50) {
      key = "xx-large"
    }
  
    const length = responseRepo[key].length
    const index = Math.floor(Math.random() * length)
    
    return responseRepo[key][index]
  }

  const inputHasValue = (id) => {
    if (document.getElementById(id)) {
      return document.getElementById(id).value
    }
    return false
  }

  /** EFFECT */
  useEffect(() => {
    setMessage(generateMessage())
  }, [inputValues.brand, inputValues.quantity, generateMessage])

  return (
    <div className="App">
      <div className="calc-wrapper">
        <h1>Bud Light Calculator</h1>
        <div className="field">
          {/* <span>Which beer were you drinking?</span> */}
          <TextSearch 
            id="brand"
            placeholder="Search for the beer were you drinking"
            beerList={beerList}
            beerListAsArray={beerListAsArray}
            handleOnChange={handleOnChange}
            inputValues={inputValues}
            setInputValues={setInputValues}
            inputHasValue={inputHasValue}
            handleInputClear={handleInputClear} />
        </div> 
        <div className="field">
          {/* <span id="how-many">Have many did you have?</span> */}
          <div className="quantity-wrapper">
            {inputHasValue("quantity")
              ? <ClearButton inputid="quantity" clickHandler={handleInputClear} />
              : null
            }
            <input
              type="text"
              id="quantity"
              name="quantity"
              placeholder="How many did you have?"
              onChange={handleOnChange}
            />
          </div>
        </div>
        <div className="field result">
          {isLoading
            ? <Loading />
            : <span style={{borderColor: warn ? "red" : "#af8600", backgroundColor: warn ? "pink" : "#ffeeb7"}} dangerouslySetInnerHTML={{ __html: message }}></span>
          }
        </div>
      </div>
      <div className="info white" onClick={handleInfoClick}></div>
      {muteAudio
        ? <div className="unmute white" onClick={handleMuteClick}></div>
        : <div className="mute white" onClick={handleMuteClick}></div>
      }
      {showPopup 
        ? <Popup muteAudio={muteAudio} handleMuteClick={handleMuteClick} handleInfoClick={handleInfoClick}/>
        : <div className="copyright">&copy; {new Date().getFullYear()} BudLightCalulator.com</div>
      }
    </div>
  )
}

export default App
