import React, { useState } from "react";
import ClearButton from "./ClearButton"

function TextSearch({ id, placeholder, beerList, beerListAsArray, inputValues, setInputValues, inputHasValue, handleInputClear }) {
  /** STATE */
  const [suggestions, setSuggestions] = useState([])
  const [text, setText] = useState("")

  const onTextChange = event => {
    let suggestions = []
    const value = event.target.value;
    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, `i`)
      suggestions = beerListAsArray.sort().filter(v => regex.test(v))
    }
    if (suggestions.length) {
      setSuggestions(suggestions)
    } else {
      setSuggestions(["No results found"])
    }
    setText(value)
  }

    /** HANDLERS */
  const handleSuggestionSelected = (value) => {
    setInputValues({ ...inputValues, "brand": value })
    setSuggestions([])
    setText(value)
  }

  const handleSearchClear = event => {
    handleInputClear(event)
    setSuggestions([])
    setText("")
  }

  /** HELPERS */
  const renderSuggestions = () => {
    if (suggestions.length === 0) {
      return null
    }
    return (
      <ul>
        {suggestions.map(beer => {
          if (beer === "No results found") {
            return <li key={beer} style={{textAlign: "center"}}><i>{`${beer}`}</i></li>
          } else {
            return <li key={beer} onClick={(e)=>handleSuggestionSelected(beer)}>{`${beer} | ${beerList[beer]}% (ABV)`}</li>
          }
        })}
      </ul>
    )
  }

  return (
    <div className="text-search">
      {inputHasValue(id)
        ? <ClearButton inputid="brand" clickHandler={handleSearchClear}/>
        : null
      }
      <input
        id={id}
        name="brand"
        type="text"
        placeholder={placeholder}
        value={text}
        onChange={onTextChange} />
      {renderSuggestions()}
    </div>
  );
}

export default TextSearch