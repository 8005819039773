const Popup = ({handleMuteClick, muteAudio, handleInfoClick}) => {
  return (
    <div className="popup">
      <div className="popup-content">
        <div className="col col-1">
          <img className="the-man-the-legend" src="./images/jon-smith.jpg" alt="Jon Smith" />
        </div>
        <div className="col col-2">
          <span>Jon Smith is a guy.<br />He had this idea to do this thing.</span>
          <br/><br/>
          <span>
            <i>
              "One day I was at a bar drinking my 4th IPA and I thought to myself how many bud lights would it of taken me to have the same amount of alcohol? And that's when I knew the world needed a Bud Light calculator."
            </i>
          </span>
          <br/><br/>
          <span className="pull-right">- Jon Smith</span>
        </div>
      </div>
      <div className="close-popup" onClick={handleInfoClick}>&times;</div>
      <div className="info black" onClick={handleInfoClick}></div>
      {muteAudio
        ? <div className="unmute black" onClick={handleMuteClick}></div>
        : <div className="mute black" onClick={handleMuteClick}></div>
      }
      <div className="drink-responsibly">Drink Responsibly</div>
    </div>
  )
}  

export default Popup
