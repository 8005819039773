const responseRepo = {
    "none": [
      "One cranberry juice please.",
      "Seriously? None?",
      "Can I interest you in a White Claw?"
    ],
    "small": [
      "Not too shabby.",
      "Takin' it easy, I see.",
      "One does not simple drink one beer!",
      "Ok Den!",
      "That’s Gucci, way to go!",
      "You are Brewtiful!",
      "Grinnin' like a possum eatin' a sweet tater.",
    ],
    "medium": [
      "Don't get carried way, now...",
      "Well alright!",
      "Working on your six pack?",
      '"Pitcher" Perfect, Nice work!',
      "You make my heart malt.",
      "In the pursuit of hoppiness...",
      "Give yourself a round of applause!",
      "Making beer disappear. Nice superpower!",
      "Livin' high on the hog! Nice Work!"
    ],
    "large": [
      "Oh boy. You're pushin' it.",
      "Beerdrop never blames itself for the flood.",
      "How about that!",
      "Get yourself a Beer Stein, nice job!",
      "Long Day? Yeah, we get it.",
      "Tell your spouse you like crafts too!"
    ],
    "x-large": [
      "Well, OK den!",
      "Oh my!",
      "Was it nickel beer night?",
      "Hey there cowboy, slow down!",
      "Poppa would be proud!",
      "Tell a friend how amazing you are!",
      "You’re lagger than life!",
      "What a trooper!"
    ],
    "xx-large": [
      "Call an ambulance!",
      "You're either lying or dead.",
      "You should check your pulse.",
      "Were you drinking out of Das Boot?",
      "Trust me, you can dance",
      "Standing Ovation!",
      "May the Lord have mercy on your soul.",
      "Why, you must be Cooter Brown!"
    ],
  }

  export default responseRepo
  